const BasePopup = ({children}) => {
  return (
    <div className="w-full h-screen overflow-y-auto fixed left-0 top-0 z-[999]">
      <div className="w-full min-h-screen py-[50px] flex items-center justify-center">
        <div className="w-[440px] p-[20px] bg-black-light border border-gray rounded-lg relative z-[998]">
          {children}
        </div>
        <div className="fixed left-0 top-0 w-full min-h-screen h-100 bg-black opacity-60 z-[990]"></div>
      </div>
    </div>
  );
}

export default BasePopup;
