import { useState, useEffect, useContext } from 'react';
/*
import {
  Heading,
  Input,
  Textarea,
  Button,
  Stack,
  FormControl,
  FormLabel,
  FormHelperText,
  Switch,
  useToast,
} from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
*/
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { db } from '../firebase';
import { setDoc, doc, serverTimestamp, getDoc  } from 'firebase/firestore';
import Dashboard from '../layouts/Dashboard';
import H1 from '../components/h1';
import FormRow from '../components/form/FormRow';
import Label from '../components/Label';
import Input from '../components/Input';
import Button from '../components/Button';
import Switch from '../components/Switch';
import { ProjectContext } from '../context/ProjectContext';
import ProjectSidebar from '../components/layout/ProjectSidebar';
import projectService from '../services/project';
import { toast } from 'react-toastify';

const ProjectsEditPage = () => {
  const {project, dispatch} = useContext(ProjectContext);
  const [data, setData] = useState({
    name: "",
    protocol: "",
    address: "",
    port: "",
    path: "",
    identifier: "",
    adminMessage: "",
    autoConnect: false,
  });
  let { id } = useParams();
  const navigate = useNavigate();

  const fetchProject = async () => {
    const res = await projectService.getWithId(id);
    dispatch({type: "SET", payload: res});
    if (project === null) {
      navigate("/");
    }
  }

  useEffect(() => {
    if (!project || project.id != id) {
      dispatch({type: "RESET"});
      fetchProject();
    }
  }, []);

  useEffect(() => {
    projectService.getWithId(id).then((project) => {
      dispatch({action: 'SET', payload: project});
      setData({
        name: project.name,
        protocol: project.broker != null ? project.broker.protocol : "",
        address: project.broker != null ? project.broker.address : "",
        port: project.broker != null ? project.broker.port : "",
        path: project.broker != null ? project.broker.path : "",
        identifier: project.identifier,
        adminMessage: project.adminMessage,
        autoConnect: project.autoConnect,
      });
    });
  }, [id]);

  /*
  const toast = useToast();
  const navigate = useNavigate();

  const fetchProjectData = async () => {
    const docSnap = await getDoc(doc(db, "projects", id));
    if (docSnap.exists()) {
      let docData = docSnap.data();
      setData({
        name: docData.name,
        protocol: docData.broker.protocol,
        address: docData.broker.address,
        port: docData.broker.port,
        path: docData.broker.path,
        identifier: docData.identifier ? docData.identifier : "",
        layout: docData.layout,
        adminMessage: docData.adminMessage ? docData.adminMessage : "",
        autoConnect: docData.autoConnect ? docData.autoConnect : false,
      });
    } else {
      console.error("Document not found.");
      navigate("/projects");
    }
  }
  */

  function handleSave(e) {
    e.preventDefault();

    let temp = project;
    temp.name = data.name;
    if (temp.broker != null) {
      temp.broker.protocol = data.protocol;
      temp.broker.address = data.address;
      temp.broker.port = data.port;
      temp.broker.path = data.path;
    } else {
      temp.broker = {
        protocol: data.protocol,
        address: data.address,
        port: data.port,
        path: data.path,
      };
    }
    temp.brokerFullUrl = `${data.protocol}://${data.address}:${data.port}${data.path}`;
    temp.identifier = data.identifier;
    temp.adminMessage = data.adminMessage;
    temp.autoConnect = data.autoConnect;
    temp.timeStamp = serverTimestamp();

    dispatch({action: 'SET', payload: temp});
    
    projectService.saveProject(temp);

    toast.success('Saved');
  }

  return (
    <Dashboard sidebarInside={<ProjectSidebar />}>
      <div className="p-[20px] max-w-[600px]">
        <H1>Project Settings</H1>

        {project != null ?
        <form className="my-8 flex flex-col gap-4">

          <FormRow>
            <Label>Name</Label>
            <Input type="text" defaultValue={data.name} onChange={(e) => setData({...data, name: e.target.value})} />
          </FormRow>

          <FormRow>
            <Label>Broker Protocol</Label>
            <Input type="text" defaultValue={data.protocol} onChange={(e) => setData({...data, protocol: e.target.value})} />
          </FormRow>

          <FormRow>
            <Label>Broker Address</Label>
            <Input type="text" defaultValue={data.address} onChange={(e) => setData({...data, address: e.target.value})} />
          </FormRow>

          <FormRow>
            <Label>Broker Port</Label>
            <Input type="text" defaultValue={data.port} onChange={(e) => setData({...data, port: e.target.value})} />
          </FormRow>

          <FormRow>
            <Label>Broker Path</Label>
            <Input type="text" defaultValue={data.path} onChange={(e) => setData({...data, path: e.target.value})} />
          </FormRow>

          <FormRow>
            <Label>Unique Identifier</Label>
            <Input type="text" defaultValue={data.identifier} onChange={(e) => setData({...data, identifier: e.target.value})} />
          </FormRow>

          <FormRow>
            <Label>Admin Message</Label>
            <Input type="text" defaultValue={data.adminMessage} onChange={(e) => setData({...data, adminMessage: e.target.value})} />
          </FormRow>

          <FormRow>
            <Label>Auto Connect ({data.autoConnect ? 
              <span className="font-bold text-green">Enabled</span>
              :
              <span className="font-bold text-red">Disabled</span>
            })</Label>
            <Switch defaultValue={data.autoConnect} onClick={(e) => setData({...data, autoConnect: e})} />
          </FormRow>

          <div className="flex items-center gap-4">
            <Button rounded={false} className="flex-1" onClick={handleSave}>Save</Button>
            <Link to={`/projects/${id}`}>
              <Button rounded={false} type="secondary">Go back</Button>
            </Link>
          </div>

        </form>
        :<></>}
      </div>
    </Dashboard>
  );
}

export default ProjectsEditPage;
