import { setDoc, doc, serverTimestamp, getDocs, collection } from 'firebase/firestore';
import { db } from '../firebase';
import { v4 as uuidv4 } from 'uuid';

const log = async (message, projectId, userId = null) => {
  let newId = new Date();
  newId = `${newId.getDate()}-${newId.getMonth()}-${newId.getFullYear()}-${newId.getHours()}:${newId.getMinutes()}:${newId.getSeconds()}`;
  await setDoc(doc(db, "logs", "general", projectId, newId), {
    messages: message,
    userId: userId,
    timeStamp: serverTimestamp(),
  });
  console.log(message);
}

const retreiveLogs = (projectId) => {
  return new Promise(async (resolve, reject) => {
    let list = [];
    try {
      const querySnapshot = await getDocs(collection(db, "logs", "general", projectId));
      querySnapshot.forEach((doc) => {
        list.push({id: doc.id, ...doc.data()});
      });
      resolve(list);
    } catch (err) {
      reject(list);
    }
  });
}

const logService = {
  log,
  retreiveLogs,
};

export default logService;
