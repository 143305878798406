import { useState, useEffect } from 'react';
import slugify from 'react-slugify';
import { auth } from '../firebase';
import { db } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc, serverTimestamp } from 'firebase/firestore';
import lodash from 'lodash';
import Dashboard from '../layouts/Dashboard';
import FormRow from '../components/form/FormRow';
import Label from '../components/Label';
import Input from '../components/Input';
import H1 from '../components/h1';
import Button from '../components/Button';
import Switch from '../components/Switch';
import { Link, useNavigate } from 'react-router-dom';
import Select from '../components/form/Select';
import projectService from '../services/project';

const UsersCreatePage = () => {
  const navigate = useNavigate();
  const [validProjects, setValidProjects] = useState([]);
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    role: "user",
    emailAlerts: true,
    projects: [],
  });

  const fetchData = async () => {
    const res = await projectService.getAll();
    if (res) {
      setValidProjects(res);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  async function handleSave(e) {
    e.preventDefault();

    if (!data.name || !data.email || !data.password) {
      return;
    }

    try {
      const res = await createUserWithEmailAndPassword(auth, data.email, data.password);
      await setDoc(doc(db, "users", res.user.uid), {
        name: data.name,
        email: data.email,
        role: data.role,
        emailAlerts: data.emailAlerts,
        projects: data.projects,
        lastLogin: null,
        timeStamp: serverTimestamp()
      });
      navigate("/users");
    } catch (err) {
      console.error(err);
    }
  }

  const toggleProject = (e) => {
    const toggle = (array, item) => lodash.xor(array, [item])

    let item = e.target.value;
    let temp = data.projects;

    temp = toggle(temp, item);
    setData({...data, projects: temp});
  }

  return (
    <Dashboard>
      <div className="p-[20px] max-w-[600px] w-full">
        <H1>New User</H1>

        <form className="my-8 flex flex-col gap-4">

          <FormRow>
            <Label>Name</Label>
            <Input type="text" defaultValue={data.name} onChange={(e) => setData({...data, name: e.target.value})} />
          </FormRow>

          <FormRow>
            <Label>E-mail</Label>
            <Input type="text" defaultValue={data.protocol} onChange={(e) => setData({...data, email: e.target.value})} />
          </FormRow>

          <FormRow>
            <Label>Password</Label>
            <Input type="text" defaultValue={data.address} onChange={(e) => setData({...data, password: e.target.value})} />
          </FormRow>

          <FormRow>
            <Label>Role</Label>
            <Select options={[{id:'user',value:'User'},{id:'admin',value:'Admin'}]} defaultValue={data.role} onChange={(e) => setData({...data, role: e})} />
          </FormRow>

          <FormRow>
            <Label>Projects</Label>
            <div className="flex flex-col gap-2">
              {validProjects.map((project, index) => 
              <label key={index} className="flex w-fit items-center gap-2 text-white">
                <input type="checkbox" onChange={toggleProject} value={project.id} checked={data.projects.includes(project.id)} />
                {project.name}
              </label>
              )}
            </div>
          </FormRow>

          <div className="flex gap-4">
            <Button className="flex-1" onClick={handleSave}>Save</Button>
            <Link to="/users">
              <Button type="secondary">Go back</Button>
            </Link>
          </div>

        </form>
      </div>
    </Dashboard>
  );
  /*
  const [isLoading, setIsLoading] = useState(false);
  const [validProjects, setValidProjects] = useState([]);
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    role: "user",
    emailAlerts: true,
    projects: [],
  });

  const toast = useToast();
  const navigate = useNavigate();

  const fetchData = async () => {
    const res = await projectService.getAll();
    if (res) {
      setValidProjects(res);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const toggleProject = (e) => {
    const toggle = (array, item) => lodash.xor(array, [item])

    let item = e.target.value;
    let temp = data.projects;

    temp = toggle(temp, item);
    setData({...data, projects: temp});
  }

  async function handleCreate(e) {
    e.preventDefault();

    if (!data.name || !data.email || !data.password) {
      toast({
        description: "Please fill the empty fields",
        status: "warning",
      });
      return;
    }

    setIsLoading(true);
    try {
      const res = await createUserWithEmailAndPassword(auth, data.email, data.password);
      await setDoc(doc(db, "users", res.user.uid), {
        name: data.name,
        email: data.email,
        role: data.role,
        emailAlerts: data.emailAlerts,
        projects: data.projects,
        lastLogin: null,
        timeStamp: serverTimestamp()
      });
      setIsLoading(false);
      navigate("/users");
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      toast({
        description: err.message,
        status: "error",
      });
    }
  }

  return (
    <DashboardLayout>
      <Heading as="h1">New User</Heading>
      <form onSubmit={handleCreate}>
        <Stack maxW="400px" mt="20px">

          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input onChange={(e) => setData({...data, name: e.target.value})} required />
          </FormControl>

          <FormControl>
            <FormLabel>E-mail</FormLabel>
            <Input type="email" onChange={(e) => setData({...data, email: e.target.value})} required />
          </FormControl>

          <FormControl>
            <FormLabel>Password</FormLabel>
            <Input type="password" onChange={(e) => setData({...data, password: e.target.value})} required />
          </FormControl>

          <FormControl>
            <FormLabel>Role</FormLabel>
            <Select onChange={(e) => setData({...data, role: e.target.value})}>
              <option value="user">User</option>
              <option value="admin">Admin</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Projects</FormLabel>
            <Stack>
              {validProjects.map((project, index) =>
                <Checkbox key={index} value={project.id} onChange={toggleProject} isChecked={data.projects.includes(project.id)}>{project.name}</Checkbox>
              )}
            </Stack>
          </FormControl>

          <Button colorScheme="blue" type="submit" isLoading={isLoading}>Create</Button>
        </Stack>
      </form>
    </DashboardLayout>
  );
  */
}

export default UsersCreatePage;
