import { createContext, useReducer, useEffect } from 'react';
import ProjectReducer from './ProjectReducer';

const INITIAL_STATE = {
  project: JSON.parse(localStorage.getItem("project")),
};

export const ProjectContext = createContext(INITIAL_STATE);

export const ProjectContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProjectReducer, INITIAL_STATE);

  useEffect(() => {
    localStorage.setItem("project", JSON.stringify(state.project));
  }, [state.project]);

  return (
    <ProjectContext.Provider value={{ project: state.project, dispatch }}>
      {children}
    </ProjectContext.Provider>
  );
}
