import { useState, useEffect } from 'react';
import Dashboard from '../layouts/Dashboard';
import slugify from 'react-slugify';
import { auth } from '../firebase';
import { db } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { getDoc, setDoc, doc, serverTimestamp } from 'firebase/firestore';
import lodash from 'lodash';
import FormRow from '../components/form/FormRow';
import Label from '../components/Label';
import Input from '../components/Input';
import H1 from '../components/h1';
import Button from '../components/Button';
import Switch from '../components/Switch';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from '../components/form/Select';
import projectService from '../services/project';
import { toast } from 'react-toastify';

const UsersEditPage = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    //password: "",
    role: "user",
    projects: [],
  });
  const [validProjects, setValidProjects] = useState([]);
  let { id } = useParams();
  const navigate = useNavigate();

  const fetchUserData = async () => {
    const docSnap = await getDoc(doc(db, "users", id));
    if (docSnap.exists()) {
      setData({...data, ...docSnap.data()});
    } else {
      console.error("Document not found.");
      navigate("/users");
    }

    const res = await projectService.getAll();
    if (res) {
      setValidProjects(res);
    }
  }

  useEffect(() => {
    fetchUserData();
  }, [id]);

  async function handleSave(e) {
    e.preventDefault();

    if (!data.name || !data.email) {
      return;
    }

    try {
      await setDoc(doc(db, "users", id), {
        name: data.name,
        email: data.email,
        role: data.role,
        projects: data.projects,
        timeStamp: serverTimestamp()
      });

      toast.success('Saved');
      //navigate("/users");
    } catch (err) {
      toast.error(err);
      console.error(err);
    }
  }

  const toggleProject = (e) => {
    const toggle = (array, item) => lodash.xor(array, [item])

    let item = e.target.value;
    let temp = data.projects;

    temp = toggle(temp, item);
    setData({...data, projects: temp});
  }

  return (
    <Dashboard>
      <div className="p-[20px] max-w-[600px] w-full">
        <H1>Edit User</H1>

        <form className="my-8 flex flex-col gap-4">

          <FormRow>
            <Label>Name</Label>
            <Input type="text" defaultValue={data.name} onChange={(e) => setData({...data, name: e.target.value})} />
          </FormRow>

          <FormRow>
            <Label>E-mail</Label>
            <Input type="text" defaultValue={data.email} onChange={(e) => setData({...data, email: e.target.value})} />
          </FormRow>

          <FormRow>
            <Label>Role</Label>
            <Select options={[{id:'user',value:'User'},{id:'admin',value:'Admin'}]} defaultValue={data.role} onChange={(e) => setData({...data, role: e})} />
          </FormRow>

          <FormRow>
            <Label>Projects</Label>
            <div className="flex flex-col gap-2">
              {validProjects.map((project, index) => 
              <label key={index} className="flex w-fit items-center gap-2 text-white">
                <input type="checkbox" onChange={toggleProject} value={project.id} checked={data.projects.includes(project.id)} />
                {project.name}
              </label>
              )}
            </div>
          </FormRow>

          <div className="flex gap-2">
            <Button onClick={handleSave}>Save</Button>
            <Link to="/users">
              <Button type="secondary">Go back</Button>
            </Link>
          </div>

        </form>
      </div>
    </Dashboard>
  );
  /*
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    //password: "",
    role: "user",
    projects: [],
  });
  const [validProjects, setValidProjects] = useState([]);
  let { id } = useParams();

  const toast = useToast();
  const navigate = useNavigate();

  const fetchUserData = async () => {
    const docSnap = await getDoc(doc(db, "users", id));
    if (docSnap.exists()) {
      setData({...data, ...docSnap.data()});
    } else {
      console.error("Document not found.");
      navigate("/users");
    }

    const res = await projectService.getAll();
    if (res) {
      setValidProjects(res);
    }
  }

  useEffect(() => {
    fetchUserData();
  }, [id]);

  async function handleSave(e) {
    e.preventDefault();

    if (!data.name || !data.email) {
      toast({
        description: "Please fill the empty fields",
        status: "warning",
      });
      return;
    }

    setIsLoading(true);
    try {
      await setDoc(doc(db, "users", id), {
        name: data.name,
        email: data.email,
        role: data.role,
        projects: data.projects,
        timeStamp: serverTimestamp()
      });
      setIsLoading(false);
      toast({
        description: "User successfully updated.",
        status: "success",
      });
      //navigate("/users");
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      toast({
        description: err.message,
        status: "error",
      });
    }
  }

  const toggleProject = (e) => {
    const toggle = (array, item) => lodash.xor(array, [item])

    let item = e.target.value;
    let temp = data.projects;

    temp = toggle(temp, item);
    setData({...data, projects: temp});
  }

  return (
    <DashboardLayout>
      <Heading as="h1">Edit User</Heading>
      <form onSubmit={handleSave}>
        <Stack maxW="400px" mt="20px">

          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input value={data.name} onChange={(e) => setData({...data, name: e.target.value})} required />
          </FormControl>

          <FormControl>
            <FormLabel>E-mail</FormLabel>
            <Input value={data.email} type="email" onChange={(e) => setData({...data, email: e.target.value})} required />
          </FormControl>

          <FormControl>
            <FormLabel>Role</FormLabel>
            <Select value={data.role} onChange={(e) => setData({...data, role: e.target.value})}>
              <option value="user">User</option>
              <option value="admin">Admin</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Projects</FormLabel>
            <Stack>
              {validProjects.map((project, index) =>
                <Checkbox key={index} value={project.id} onChange={toggleProject} isChecked={data.projects.includes(project.id)}>{project.name}</Checkbox>
              )}
            </Stack>
          </FormControl>

          <Button colorScheme="blue" type="submit" isLoading={isLoading}>Save</Button>
        </Stack>
      </form>
    </DashboardLayout>
  );
*/
}

export default UsersEditPage;
