import { useEffect, useState } from "react";

const Switch = ({ defaultValue = false, onClick = () => {} }) => {
  const [state, setState] = useState(defaultValue);
  const containerClass = "transition-all duration-200 flex items-center px-1 relative w-[50px] h-[24px] border border-gray rounded cursor-pointer bg-black hover:opacity-100 opacity-80";
  const toggleClass = "transition-all duration-200 w-[20px] h-[14px] rounded";

  useEffect(() => {
    setState(defaultValue);
  }, [defaultValue]);

  function onClickHandle() {
    const newState = !state;
    onClick(newState);
    setState(newState);
  }

  return (
    <div className={ containerClass + " " + (state ? "justify-end" : "justify-start")} onClick={onClickHandle}>
      <div className={ toggleClass + " " + (state ? "bg-orange" : "bg-gray")}></div> 
    </div>
  );

  /*
  return (
    <div className="relative w-[50px] h-[20px] border border-gray rounded cursor-pointer" onClick={onClickHandle}>
      <div className={"absolute top-[2px] w-[24px] h-[14px] rounded " + (state ? "bg-orange left-[2px]" : "bg-gray right-[2px]")}></div> 
    </div>
  );
  */
}

export default Switch;
