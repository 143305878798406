import { useContext, useState } from 'react';
import Button from '../Button';
import EditWidgetPopup from '../popup/EditWidgetPopup';
import DeleteWidgetPopup from '../popup/DeleteWidgetPopup';
import { ProjectStateContext } from '../../context/ProjectStateContext';
import { ProjectContext } from '../../context/ProjectContext';
import projectService from '../../services/project';
import { toast } from 'react-toastify';
/*
import { AuthContext } from '../../context/AuthContext';
import logService from '../../services/log';
*/

const WidgetBase = ({children, widget, sectionId}) => {
  const projectContext = useContext(ProjectContext);
  const {projectState, dispatch} = useContext(ProjectStateContext);
  const [editPopup, setEditPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);

  function handleMoveRight() {
    let temp = projectContext.project;
    temp.layout.map((section) => {
      if (section.uuid == sectionId) {
        let currentIndex = null;
        let newIndex = null;

        // gather indexes
        section.children.map((item, index) => {
          if (currentIndex != null && newIndex == null) {
            newIndex = index;
          }

          if (item.uuid == widget.uuid) {
            currentIndex = index;
          }
        });

        // move right
        if (currentIndex != null && newIndex != null) {
          let current = section.children[currentIndex];
          section.children[currentIndex] = section.children[newIndex];
          section.children[newIndex] = current;
        }
      }
    });

    projectContext.dispatch({action: 'SET', payload: temp});

    dispatch({type: 'SET', payload: projectState});

    projectService.saveProject(temp);
  }

  function handleMoveLeft() {
    let temp = projectContext.project;
    temp.layout.map((section) => {
      if (section.uuid == sectionId) {
        let currentIndex = null;
        let newIndex = null;

        // gather indexes
        section.children.map((item, index) => {
          if (item.uuid == widget.uuid) {
            currentIndex = index;
          }
        });

        if (currentIndex != 0) {
          newIndex = currentIndex - 1;
        }

        // move left
        if (currentIndex != null && newIndex != null) {
          let current = section.children[currentIndex];
          section.children[currentIndex] = section.children[newIndex];
          section.children[newIndex] = current;
        }
      }
    });

    projectContext.dispatch({action: 'SET', payload: temp});

    dispatch({type: 'SET', payload: projectState});

    projectService.saveProject(temp);
  }

  function handleMoveTop() {
    let temp = projectContext.project;
    let widgetTemp = null;
    let widgetTempIndex = null;
    let oldSectionIndex = null;

    if (temp.layout.length == 1) {
      toast.error('No section available on top');
      return;
    }

    temp.layout.map((section, secIndex) => {
      if (section.uuid == sectionId) {
        oldSectionIndex = secIndex;

        section.children.map((item, index) => {
          if (item.uuid == widget.uuid) {
            widgetTemp = item;
            widgetTempIndex = index;
          }
        });

        if (oldSectionIndex != 0) {
          section.children.splice(widgetTempIndex, 1);
        }
      }
    });

    if (oldSectionIndex == 0) {
      toast.error('No section available on top');
      return;
    }

    temp.layout[oldSectionIndex - 1].children.push(widgetTemp);

    projectContext.dispatch({action: 'SET', payload: temp});

    dispatch({type: 'SET', payload: projectState});

    projectService.saveProject(temp);
  }

  function handleMoveDown() {
    let temp = projectContext.project;
    let widgetTemp = null;
    let widgetTempIndex = null;
    let oldSectionIndex = null;

    if (temp.layout.length == 1) {
      toast.error('No section available on bottom');
      return;
    }

    temp.layout.map((section, secIndex) => {
      if (section.uuid == sectionId) {
        oldSectionIndex = secIndex;

        section.children.map((item, index) => {
          if (item.uuid == widget.uuid) {
            widgetTemp = item;
            widgetTempIndex = index;
          }
        });

        if (oldSectionIndex != temp.layout.length - 1) {
          section.children.splice(widgetTempIndex, 1);
        }
      }
    });

    if (oldSectionIndex == temp.layout.length - 1) {
      toast.error('No section available on bottom');
      return;
    }

    temp.layout[oldSectionIndex + 1].children.push(widgetTemp);

    projectContext.dispatch({action: 'SET', payload: temp});

    dispatch({type: 'SET', payload: projectState});

    projectService.saveProject(temp);
  }

  return (
    <>
      <div className="p-[6px] bg-black-light border border-gray rounded-lg relative h-fit">
        <h3 className="text-white text-[13px] font-bold">{widget.title}</h3>
        <div>
          {children}
        </div>
        {projectState == 'edit' ? 
          <div className="w-full h-full absolute left-0 top-0 rounded-lg">
            <div className="flex flex-col gap-2 justify-center h-full relative z-[99]">
              <div className="flex justify-center">
                <Button type="secondary" onClick={handleMoveTop}>
                  <img src="/icons/arrow-left.svg" className="w-[18px] rotate-90" />
                </Button>
              </div>
              <div className="flex gap-2 items-center justify-center">
                <Button type="secondary" onClick={handleMoveLeft}>
                  <img src="/icons/arrow-left.svg" className="w-[18px]" />
                </Button>
                <Button type="secondary" onClick={() => setEditPopup(true)}>
                  <img src="/icons/pen.svg" className="w-[18px]" />
                </Button>
                <Button type="danger" onClick={() => setDeletePopup(true)}>
                  <img src="/icons/thrash.svg" />
                </Button>
                <Button type="secondary" onClick={handleMoveRight}>
                  <img src="/icons/arrow-right.svg" className="w-[18px]" />
                </Button>
              </div>
              <div className="flex justify-center">
                <Button type="secondary" onClick={handleMoveDown}>
                  <img src="/icons/arrow-left.svg" className="w-[18px] -rotate-90" />
                </Button>
              </div>
            </div>
            <div className="w-full h-full bg-black opacity-70 absolute left-0 top-0 rounded-lg z-[98]"></div>
          </div>
        : <></>}
      </div>
      {deletePopup ?
        <DeleteWidgetPopup widget={widget} sectionId={sectionId} onDelete={() => setDeletePopup(false)} onCancel={() => setDeletePopup(false)} />
      : <></>}
      {editPopup ?
        <EditWidgetPopup widget={widget} sectionId={sectionId} onSave={() => setEditPopup(false)} onClose={() => setEditPopup(false)} />
      : <></>}
    </>
  );
  /*
  const {project, dispatch} = useContext(ProjectContext);
  const {currentUser} = useContext(AuthContext);
  const [editPopup, setEditPopup] = useState(false);
  const toast = useToast();

    let isVisible = widget.visible !== undefined && widget.visible !== null ? widget.visible : true;

  const handleDelete = () => {
    let temp = project;
    temp.layout.map((section) => {
      return section.children = section.children.filter(data => data.uuid !== uuid);
    });
    dispatch({type: "SET", payload: temp});

    logService.log(`widget ${title} deleted by ${currentUser.name}`, project.id, currentUser.uid);

    temp = project;

    toast({
      description: "Widget deleted.",
      status: "success",
      duration: 1000,
    });
  }

    if (showSettings) {
      return (
        <GridItem
          rowSpan={1}
          colSpan={1}
        >
          <Box 
            h="100%"
            border="1px"
            borderColor="gray.200"
            borderRadius="lg"
            bg="gray.50"
            p="20px"
          >
            <Flex justifyContent="space-between">
              <Text fontSize="2xl" mb="10px">{title}</Text>
              <WidgetEditPopup isOpen={editPopup} closeFunc={() => setEditPopup(false)} widget={widget} />
              <Menu>
                <MenuButton as={Button}><SettingsIcon /></MenuButton>
                <MenuList>
                  <MenuItem icon={<EditIcon />} onClick={() => setEditPopup(true)}>Edit</MenuItem>
                  <MenuItem color="red.500" icon={<DeleteIcon />} onClick={handleDelete}>Delete</MenuItem>
                </MenuList>
              </Menu>
            </Flex>
            <Flex alignItems="center" justifyContent="center">
              {children}
            </Flex>
          </Box>
        </GridItem>
      );
    } else {
        if(isVisible) {
          return (
            <GridItem
              rowSpan={1}
              colSpan={1}
            >
              <Box 
                h="100%"
                border="1px"
                borderColor="gray.200"
                borderRadius="lg"
                bg="gray.50"
                p="20px"
              >
                <Flex justifyContent="space-between">
                  <Text fontSize="2xl" mb="10px">{title}</Text>
                </Flex>
                <Flex h="50%" alignItems="center" justifyContent="center">
                  {children}
                </Flex>
              </Box>
            </GridItem>
          );
        } else {
            return <></>;
        }
    }
    */
}

export default WidgetBase;
