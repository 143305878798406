import { useContext, useEffect, useState } from 'react';
import WidgetBase from './WidgetBase';
import GaugeChart from '../chart/GaugeChart';
import mqttService from '../../services/mqtt';
import { ConnectionContext } from '../../context/ConnectionContext';
import { ProjectContext } from '../../context/ProjectContext';

const WidgetGauge = ({widget, sectionId}) => {
  const { connection } = useContext(ConnectionContext);
  const { project, dispatch } = useContext(ProjectContext);
  const [states, setStates] = useState([]);
  const items = widget.fields && widget.fields.length ? widget.fields[1].value : [];

  /*
  useEffect(() => {
    let temp = [];
    items.map(() => {
      temp.push(20);
    });
    setStates(temp);
  }, []);
  */

  useEffect(() => {
    if (connection && connection.connected) {
      items.map((item, key) => {
        mqttService.subscribe(connection.client, project.identifier + item.topic);
        mqttService.listenTopic(connection.client, project.identifier + item.topic, (message) => {
          const intMessage = parseInt(message);
          let temp = states;
          if (temp == null || temp[key] == null)
            return;
          temp[key].push(intMessage);
          setStates(temp);
          dispatch({type: 'RENDER'});
        });
      });
    }
  }, [connection]);

  function getColor(name) {
    let response = "#ffffff";

    switch(name) {
      case 'orange':
        response = "#F38A4F";
        break;
      case 'red':
        response = "#C83F3F";
        break;
      case 'blue':
        response = "#338BDC";
        break;
      case 'green':
        response = "#3BC559";
        break;
    }

    return response;
  }

  return (
    <WidgetBase widget={widget} sectionId={sectionId}>
      <div className="flex flex-col gap-2">
      {items.map((item, index) =>
        <div key={index}>
          {index != 0 ?
            <div className="w-full border border-dashed border-gray"></div>
          : <></>}
          <div>
            <div className="text-white">{item.label}</div>
            <GaugeChart radius={100} color={getColor(item.color)} percent={states[index]} min={parseInt(item['min-value'])} max={parseInt(item['max-value'])} />
          </div>
        </div>
      )}
      </div>
    </WidgetBase>
  );
}

export default WidgetGauge;
