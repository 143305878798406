import { useState, useContext } from 'react';
import Auth from '../layouts/Auth';
import { AuthContext } from '../context/AuthContext';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../firebase';
import { useNavigate, Link } from 'react-router-dom';
import { setDoc, doc, serverTimestamp, getDoc  } from 'firebase/firestore';
import userService from '../services/user';
import FormRow from '../components/form/FormRow';
import Label from '../components/Label';
import Input from '../components/Input';
import Button from '../components/Button';
import H1 from '../components/h1';
import { toast } from 'react-toastify';

const LoginPage = () => {
  const navigate = useNavigate();

  const {dispatch} = useContext(AuthContext);
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!inputs.email || !inputs.password) {
      toast.error('Please fill empty fields');
      return;
    }

    try {
      const res = await signInWithEmailAndPassword(auth, inputs.email, inputs.password);
      const user = res.user;
      let userWData = {...user};

      const userRes = await userService.getWithAttribute("email", user.email);
      userWData = {...userRes, ...userWData};

        try {
          await setDoc(doc(db, "users", userWData.uid), {
            ...userRes,
            lastLogin: serverTimestamp(),
          });
        } catch (err) {
          toast.error(err);
          console.error(err);
        }

      dispatch({type: "LOGIN", payload: userWData});
      navigate("/");
    } catch (err) {
      let msg = "";
      switch(err.code) {
        case 'auth/user-not-found':
          msg = "User not found!";
          break;
        case 'auth/invalid-email':
          msg = "E-mail is not valid!";
          break;
        case 'auth/wrong-password':
          msg = "Wrong password!";
          break;
        default:
          msg = err.code;
          break;
      }
      toast.error(msg);
    }
  }

  return (
    <Auth>
      <div className="mt-[20px] p-[20px] max-w-[600px] w-full bg-black-light border border-gray rounded-lg">
        <H1>Login</H1>
        <form className="mt-[20px] flex flex-col gap-4" onSubmit={handleLogin}>
          <FormRow>
            <Label>E-mail</Label>
            <Input defaultValue={inputs.email} type="email" onChange={(e) => setInputs({...inputs, email: e.target.value})} />
          </FormRow>
          <FormRow>
            <Label>Password</Label>
            <Input defaultValue={inputs.password} type="password" onChange={(e) => setInputs({...inputs, password: e.target.value})} />
          </FormRow>
          <div className="flex items-center justify-between">
            <Button onClick={handleLogin}>Login</Button> 
            <Link className="text-white hover:text-orange" to="/password-reset">Forgot password?</Link>
          </div>
        </form>
      </div>
    </Auth>
  );
  /*

  return (
    <BlankLayout>
      <Box maxW="400px" my="100px" mx="auto">
        <Image src="/logo.png" alt="artresearch.tech" boxSize='150px' mx="auto" mb="20px" />
        <Heading as="h1" mb="20px">Login</Heading>
        <form onSubmit={handleLogin}>
          <Stack spacing={3}>
            <FormControl>
              <FormLabel>E-mail</FormLabel>
              <Input onChange={(event) => setEmail(event.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel>Password</FormLabel>
              <Input type="password" onChange={(event) => setPassword(event.target.value)} />
            </FormControl>
            <Link to="/password-reset">Forgot password?</Link>
            <Button colorScheme="blue" isLoading={isLoading} type="submit">Login</Button>
            <Text fontWeight="bold" color="tomato">{errorMsg.length ? errorMsg : ""}</Text>
          </Stack>
        </form>
      </Box>
    </BlankLayout>
  );
  */
}

export default LoginPage;
