import { collection, getDocs, deleteDoc, doc, query, where } from 'firebase/firestore';
import { db } from '../firebase';

function getWithAttribute(attribute, value) {
  return new Promise(async (resolve, reject) => {
    let user = null;
    try {
      const q = query(collection(db, "users"), where(attribute, '==', value));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        user = doc.data();
      });
      resolve(user);
    } catch (err) {
      reject(err);
    }
  });
}

function getAll() {
  return new Promise(async (resolve, reject) => {
    let list = [];
    try {
      const querySnapshot = await getDocs(collection(db, "users"));
      querySnapshot.forEach((doc) => {
        list.push({id: doc.id, ...doc.data()});
      });
      resolve(list);
    } catch (err) {
      reject(list);
    }
  });
}

function deleteWithId(id) {
  return new Promise(async (resolve, reject) => {
    try {
      await deleteDoc(doc(db, "users", id));
      resolve(true);
    } catch (err) {
      resolve(false);
    }
  });
}

const userService = {
  getAll,
  deleteWithId,
  getWithAttribute,
};

export default userService;
