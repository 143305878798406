import { useContext, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { updatePassword } from 'firebase/auth';
import { auth, db } from '../firebase';

// v2
import Dashboard from '../layouts/Dashboard';
import H1 from '../components/h1';
import Input from '../components/Input';
import Label from '../components/Label';
import FormRow from '../components/form/FormRow';
import Button from '../components/Button';
import { toast } from 'react-toastify';

const SettingsPage = () => {
  const {currentUser, dispatch} = useContext(AuthContext);
  const [data, setData] = useState({
    name: currentUser.name,
    email: currentUser.email,
    password: "",
    emailAlerts: currentUser.emailAlerts ? currentUser.emailAlerts : true,
  });

  function handleSave(e) {
    e.preventDefault();

    if (!data.name || !data.email) {
      return;
    }

    updateDoc(doc(db, "users", currentUser.uid), {
      name: data.name,
      email: data.email,
      emailAlerts: data.emailAlerts,
      timeStamp: serverTimestamp()
    }).then(() => {
      if (data.password !== "") {
        updatePassword(auth.currentUser, data.password).then(() => {});
      }

      let tempUser = {...currentUser, name: data.name, email: data.email};
      dispatch({type: "LOGIN", payload: tempUser});

      toast.success('Saved');
    }).catch((err) => {
      toast.error(err);
      console.log(err);
    });
  }

  return (
    <Dashboard>
      <div className="p-[20px]">
        <H1>Settings</H1>

        <form className="flex flex-col gap-4 max-w-[600px] py-4">
          <FormRow>
            <Label>Name</Label>
            <Input defaultValue={data.name} onChange={(e) => setData({...data, name:e.target.value})} />
          </FormRow>
          <FormRow>
            <Label>E-mail</Label>
            <Input type="email" defaultValue={data.email} onChange={(e) => setData({...data, email:e.target.value})} />
          </FormRow>
          <FormRow>
            <Label>Password</Label>
            <Input type="password" onChange={(e) => setData({...data, password:e.target.value})} />
          </FormRow>
          <Button onClick={handleSave}>Save</Button> 
        </form>
      </div>
    </Dashboard>
  );
  /*
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  async function handleSave(e) {
    e.preventDefault();

    if (!data.name || !data.email) {
      return;
    }

    try {
      setIsLoading(true);
      await updateDoc(doc(db, "users", currentUser.uid), {
        name: data.name,
        email: data.email,
        emailAlerts: data.emailAlerts,
        timeStamp: serverTimestamp()
      });

      if (data.password !== "") {
        await updatePassword(auth.currentUser, data.password);
      }

      let tempUser = {...currentUser, name: data.name, email: data.email};
      dispatch({type: "LOGIN", payload: tempUser});
      setIsLoading(false);
      toast({
        description: "Settings successfully saved.",
        status: "success",
      });
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }

  return (
    <DashboardLayout>
      <Heading as="h1">Profile Settings</Heading>
      <Stack maxW="400px" mt="20px">

        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input value={data.name} onChange={(e) => setData({...data, name: e.target.value})} />
        </FormControl>

        <FormControl>
          <FormLabel>E-mail</FormLabel>
          <Input type="email" value={data.email} onChange={(e) => setData({...data, email: e.target.value})} />
        </FormControl>

        <FormControl>
          <FormLabel>Password</FormLabel>
          <Input mb="20px" type="password" onChange={(e) => setData({...data, password: e.target.value})} />
        </FormControl>

        <Button w="fit-content" onClick={handleSave} colorScheme="blue" isLoading={isLoading}>Save</Button>
      </Stack>
    </DashboardLayout>
  );
  */
}

export default SettingsPage;
