import React, { useEffect, useContext } from 'react';
import { ProjectContext } from '../context/ProjectContext';
import { useParams } from 'react-router-dom';
import projectService from '../services/project';
import { useNavigate } from 'react-router-dom';

// v2
import Dashboard from '../layouts/Dashboard';
import ProjectHeader from '../components/ProjectHeader';
import ProjectSection from '../components/ProjectSection';
import ProjectSidebar from '../components/layout/ProjectSidebar';
import { ConnectionContext } from '../context/ConnectionContext';

const HomePage = () => {
  const {project, dispatch} = useContext(ProjectContext);
  const connectionContext = useContext(ConnectionContext);

  let { id } = useParams();
  const navigate = useNavigate();

  const fetchProject = async () => {
    const res = await projectService.getWithId(id);
    dispatch({type: "SET", payload: res});
    if (project === null) {
      navigate("/");
    }
  }

  useEffect(() => {
    if (!project || project.id != id) {
      dispatch({type: "RESET"});
      fetchProject();
    }

    return () => {
      // prevent keeping connection after switching to other projects
      connectionContext.dispatch({type: 'DISCONNECT'});
    }
  }, []);

  return (
    <Dashboard sidebarInside={<ProjectSidebar />}>

      <ProjectHeader title={project.title} />

      <div className="h-[60px]"></div>

      {project != null && project.layout != null && !project.layout.length ?
      <div className="p-[20px]">
        <p className="text-white mb-4">There are no sections available, please create a new section from the sidebar on the left.</p>
      </div>
      : <></>}

      { project != null && project.layout != null ? project.layout.map((section, index) =>
        <ProjectSection key={index} section={section} />
      ) : <></> }

    </Dashboard>
  );
}

export default HomePage;
