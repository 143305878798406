import { createContext, useReducer, useEffect } from 'react';
import ProjectStateReducer from './ProjectStateReducer';

const INITIAL_STATE = {
  projectState: localStorage.getItem("project_state") || "display",
};

export const ProjectStateContext = createContext(INITIAL_STATE);

export const ProjectStateContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProjectStateReducer, INITIAL_STATE);

  useEffect(() => {
    localStorage.setItem("project_state", state.projectState);
  }, [state.projectState]);

  return (
    <ProjectStateContext.Provider value={{ projectState: state.projectState, dispatch }}>
      {children}
    </ProjectStateContext.Provider>
  );
}
