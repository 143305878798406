import { createContext, useReducer, useEffect } from 'react';
import ConnectionReducer from './ConnectionReducer';

const INITIAL_STATE = {
  connection: null,
};

export const ConnectionContext = createContext(INITIAL_STATE);

export const ConnectionContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ConnectionReducer, INITIAL_STATE);

  /*
  useEffect(() => {
    localStorage.setItem("connection", JSON.stringify(state.connection));
  }, [state.connection]);
  */

  return (
    <ConnectionContext.Provider value={{ connection: state.connection, dispatch }}>
      {children}
    </ConnectionContext.Provider>
  );
}
