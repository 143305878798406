import { useContext, useEffect, useState } from "react";
import { ConnectionContext } from "../../context/ConnectionContext";
import { ProjectContext } from "../../context/ProjectContext";
import mqttService from "../../services/mqtt";
import MultiLineChart from "../chart/MultiLineChart";
import WidgetBase from "./WidgetBase";

const WidgetMultiLineChart = ({ widget, sectionId }) => {
  const { connection } = useContext(ConnectionContext);
  const { project, dispatch } = useContext(ProjectContext);
  const [states, setStates] = useState([]);
  const items = widget.fields && widget.fields.length ? widget.fields[1].value : [];

  useEffect(() => {
    let temp = [];
    items.map((item) => {
      temp.push({...item, values: []});
    });
    setStates(temp);
  }, []);

  useEffect(() => {
    if (connection && connection.connected) {
      items.map((item, key) => {
        mqttService.subscribe(connection.client, project.identifier + item.topic);
        mqttService.listenTopic(connection.client, project.identifier + item.topic, (message) => {
          const intMessage = parseInt(message);
          let temp = states;
          if (temp == null || temp[key] == null)
            return;
          temp[key].values.push(intMessage);
          setStates(temp);
          dispatch({type: 'RENDER'});
        });
      });
    }
  }, [connection]);

  return (
    <WidgetBase widget={widget} sectionId={sectionId}>
      <MultiLineChart values={states} />
    </WidgetBase>
  );
}

export default WidgetMultiLineChart;
