import BasePopup from "./BasePopup";
import Button from '../Button';
import FormRow from '../form/FormRow';
import Label from '../Label';
import Input from '../Input';
import { useState, useContext } from "react";
import { ProjectContext } from '../../context/ProjectContext';
import { setDoc, doc, serverTimestamp  } from 'firebase/firestore';
import { db } from '../../firebase';
import projectService from '../../services/project';
import logService from "../../services/log";
import { AuthContext } from "../../context/AuthContext";

const EditSectionPopup = ({section, onSave = () => {}, onClose = () => {}}) => {
  const {project, dispatch} = useContext(ProjectContext);
  const {currentUser} = useContext(AuthContext);
  const [titleInput, setTitleInput] = useState(section.title);

  function onSaveAction() {
    if (!titleInput || titleInput == section.title) {
      onSave();
      return;
    }

    let temp = project;
    temp.layout.map((val) => {
      if (val.uuid === section.uuid) {
        val.title = titleInput;
      }
    });

    dispatch({type: "SET", payload: temp});

    projectService.saveProject(project);

    logService.log(`section ${titleInput} updated by ${currentUser.name}`, project.id, currentUser.uid);

    onSave();
  }

  return (
    <BasePopup>
      <div>
        <div className="text-[20px] text-white font-bold">Edit Section</div>
      </div>

      <form className="my-6">
        <FormRow>
          <Label>Title</Label>
          <Input defaultValue={titleInput} onChange={(e) => setTitleInput(e.target.value)} />
        </FormRow>
      </form>
      
      <div className="flex gap-2">
        <Button type="primary" onClick={() => onSaveAction()}>Save</Button>
        <Button type="secondary" onClick={onClose}>Close</Button>
      </div>
    </BasePopup>
  );
}

export default EditSectionPopup;
