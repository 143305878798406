const widgets = [
  {
    title: "Text",
    value: "text",
    icon: "/icons/text-widget.svg",
    inputs: [
      {
        label: "Title",
        name: "title",
        element: "input",
        type: "text",
        required: true,
      },
      {
        label: "Visible to everyone",
        name: "visible",
        element: "switch",
        value: true,
      },
      {
        label: "Topic",
        name: "topic",
        element: "input",
        type: "text",
        required: true,
      },
    ],
  },
  {
    title: "Number",
    value: "number",
    icon: "/icons/pin.svg",
    inputs: [
      {
        label: "Title",
        name: "title",
        element: "input",
        type: "text",
        required: true,
      },
      {
        label: "Visible to everyone",
        name: "visible",
        element: "switch",
        value: true,
      },
      {
        label: "List",
        name: "list",
        element: "list",
        inputs: [
          {
            label: "Label",
            name: "label",
            element: "input",
            type: "text",
            required: true,
          },
          {
            label: "MIN value",
            name: "min-value",
            element: "input",
            type: "text",
          },
          {
            label: "MAX value",
            name: "max-value",
            element: "input",
            type: "text",
          },
          {
            label: "Unit",
            name: "unit",
            element: "input",
            type: "text",
          },
          {
            label: "Topic",
            name: "topic",
            element: "input",
            type: "text",
          },
        ],
      },
    ],
  },
  {
    title: "Line Chart",
    value: "line-chart",
    icon: "/icons/line-graph.svg",
    inputs: [
      {
        label: "Title",
        name: "title",
        element: "input",
        type: "text",
        required: true,
      },
      {
        label: "Visible to everyone",
        name: "visible",
        element: "switch",
        value: true,
      },
      {
        label: "List",
        name: "list",
        element: "list",
        inputs: [
          {
            label: "Label",
            name: "label",
            element: "input",
            type: "text",
            required: true,
          },
          {
            label: "Topic",
            name: "topic",
            element: "input",
            type: "text",
          },
          {
            label: "Color",
            name: "color",
            element: "select",
            options: [
              {id: 'red', value: 'Red'},
              {id: 'green', value: 'Green'},
              {id: 'blue', value: 'Blue'},
              {id: 'orange', value: 'Orange'},
            ]
          },
          /*
          {
            label: "Identifier",
            name: "identifier",
            element: "input",
            type: "text",
          },
          */
        ],
      },
    ],
  },
  {
    title: "Multi Line Chart",
    value: "multi-line-chart",
    icon: "/icons/line-graph.svg",
    inputs: [
      {
        label: "Title",
        name: "title",
        element: "input",
        type: "text",
        required: true,
      },
      {
        label: "Visible to everyone",
        name: "visible",
        element: "switch",
        value: true,
      },
      {
        label: "List",
        name: "list",
        element: "list",
        inputs: [
          {
            label: "Label",
            name: "label",
            element: "input",
            type: "text",
            required: true,
          },
          {
            label: "Topic",
            name: "topic",
            element: "input",
            type: "text",
          },
          {
            label: "Color",
            name: "color",
            element: "select",
            options: [
              {id: 'red', value: 'Red'},
              {id: 'green', value: 'Green'},
              {id: 'blue', value: 'Blue'},
              {id: 'orange', value: 'Orange'},
            ]
          },
          /*
          {
            label: "Identifier",
            name: "identifier",
            element: "input",
            type: "text",
          },
          */
        ],
      },
    ],
  },
  {
    title: "Bar Chart",
    value: "bar-chart",
    icon: "/icons/bar-graph.svg",
    inputs: [
      {
        label: "Title",
        name: "title",
        element: "input",
        type: "text",
        required: true,
      },
      {
        label: "Visible to everyone",
        name: "visible",
        element: "switch",
        value: true,
      },
      {
        label: "List",
        name: "list",
        element: "list",
        inputs: [
          {
            label: "Label",
            name: "label",
            element: "input",
            type: "text",
            required: true,
          },
          {
            label: "Topic",
            name: "topic",
            element: "input",
            type: "text",
          },
          {
            label: "MIN value",
            name: "min-value",
            element: "input",
            type: "text",
          },
          {
            label: "MAX value",
            name: "max-value",
            element: "input",
            type: "text",
          },
          {
            label: "Unit",
            name: "unit",
            element: "input",
            type: "text",
          },
          {
            label: "Color",
            name: "color",
            element: "select",
            options: [
              {id: 'red', value: 'Red'},
              {id: 'green', value: 'Green'},
              {id: 'blue', value: 'Blue'},
              {id: 'orange', value: 'Orange'},
            ]
          },
        ],
      },
    ],
  },
  {
    title: "Indicator",
    value: "indicator",
    icon: "/icons/light-bulb.svg",
    inputs: [
      {
        label: "Title",
        name: "title",
        element: "input",
        type: "text",
        required: true,
      },
      {
        label: "Visible to everyone",
        name: "visible",
        element: "switch",
        value: false,
      },
      {
        label: "List",
        name: "list",
        element: "list",
        inputs: [
          {
            label: "Label",
            name: "label",
            element: "input",
            type: "text",
          },
          {
            label: "Topic",
            name: "topic",
            element: "input",
            type: "text",
          },
          /*
          {
            label: "Icon",
            name: "icon",
            element: "media",
          },
          */
          {
            label: "ON",
            name: "on",
            element: "input",
            type: "text",
          },
          {
            label: "OFF",
            name: "off",
            element: "input",
            type: "text",
          },
          {
            label: "Color",
            name: "color",
            element: "select",
            options: [
              {id: 'red', value: 'Red'},
              {id: 'green', value: 'Green'},
              {id: 'blue', value: 'Blue'},
              {id: 'orange', value: 'Orange'},
            ]
          },
          /*
          {
            label: "Topic Identifier",
            name: "topic-identifier",
            element: "input",
            type: "text",
          },
          */
        ],
      },
    ],
  },
  {
    title: "Button",
    value: "button",
    icon: "/icons/button-widget.svg",
    inputs: [
      {
        label: "Title",
        name: "title",
        element: "input",
        type: "text",
        required: true,
      },
      {
        label: "Visible to everyone",
        name: "visible",
        element: "switch",
        value: true,
      },
      {
        label: "List",
        name: "list",
        element: "list",
        inputs: [
          {
            label: "Label",
            name: "label",
            element: "input",
            type: "text",
          },
          {
            label: "Topic",
            name: "topic",
            element: "input",
            type: "text",
          },
          {
            label: "Message to Send",
            name: "message",
            element: "input",
            type: "text",
          },
          {
            label: "Color",
            name: "color",
            element: "select",
            options: [
              {id: 'gray', value: 'Gray'},
              {id: 'red', value: 'Red'},
              {id: 'green', value: 'Green'},
              {id: 'blue', value: 'Blue'},
              {id: 'orange', value: 'Orange'},
            ]
          },
          {
            label: "Icon",
            name: "icon",
            element: "icon",
            options: [
              {id: '', value: 'No icon', icon: ''},
              {id: 'warning-circle', value: 'Warning - Circle', icon: './icons/warning-circle.svg'},
              {id: 'warning-triangle', value: 'Warning - Triangle', icon: './icons/warning-triangle.svg'},
              {id: 'check-circle', value: 'Check - Circle', icon: './icons/check-circle.svg'},
              {id: 'check', value: 'Check', icon: './icons/check.svg'},
              {id: 'question-mark', value: 'Question Mark', icon: './icons/question-mark.svg'},
              {id: 'plus', value: 'Plus', icon: './icons/plus.svg'},
              {id: 'add-circle', value: 'Add Circle', icon: './icons/add-circle.svg'},
              {id: 'minus-circle', value: 'Minus Circle', icon: './icons/minus-circle.svg'},
              {id: 'minus', value: 'Minus', icon: './icons/minus.svg'},
            ]
          },
        ]
      },
    ],
  },
  {
    title: "Input",
    value: "input",
    icon: "/icons/input-field.svg",
    inputs: [
      {
        label: "Title",
        name: "title",
        element: "input",
        type: "text",
        required: true,
      },
      {
        label: "Visible to everyone",
        name: "visible",
        element: "switch",
        value: true,
      },
      {
        label: "List",
        name: "list",
        element: "list",
        type: "list",
        inputs: [
          {
            label: "SET Topic",
            name: "setterTopic",
            element: "input",
            type: "text",
          },
          {
            label: "SET is Admin Only?",
            name: "setterAdminOnly",
            element: "switch",
          },
          {
            label: "GET Topic",
            name: "getterTopic",
            element: "input",
            type: "text",
          },
          {
            label: "GET is Admin Only?",
            name: "getterAdminOnly",
            element: "switch",
          },
          {
            label: "Reply Topic",
            name: "replyTopic",
            element: "input",
            type: "text",
          },
          {
            label: "Topic",
            name: "topic",
            element: "input",
            type: "text",
          },
        ]
      },
    ],
  },
  {
    title: "Toggle",
    value: "toggle",
    icon: "/icons/switch.svg",
    inputs: [
      {
        label: "Title",
        name: "title",
        element: "input",
        type: "text",
        required: true,
      },
      {
        label: "Visible to everyone",
        name: "visible",
        element: "switch",
        value: true,
      },
      {
        label: "List",
        name: "list",
        element: "list",
        type: "list",
        inputs: [
          {
            label: "Label",
            name: "label",
            element: "input",
            type: "text",
          },
          {
            label: "Topic",
            name: "topic",
            element: "input",
            type: "text",
          },
          {
            label: "ON Value",
            name: "onValue",
            element: "input",
            type: "text",
          },
          {
            label: "OFF Value",
            name: "offValue",
            element: "input",
            type: "text",
          },
        ],
      },
    ],
  },
  {
    title: "Gauge",
    value: "gauge",
    icon: "/icons/gauge.svg",
    inputs: [
      {
        label: "Title",
        name: "title",
        element: "input",
        type: "text",
        required: true,
      },
      {
        label: "Visible to everyone",
        name: "visible",
        element: "switch",
        value: true,
      },
      {
        label: "List",
        name: "list",
        element: "list",
        type: "list",
        inputs: [
          {
            label: "Label",
            name: "label",
            element: "input",
            type: "text",
          },
          {
            label: "Topic",
            name: "topic",
            element: "input",
            type: "text",
          },
          {
            label: "Min Value",
            name: "min-value",
            element: "input",
            type: "text",
          },
          {
            label: "Max Value",
            name: "max-value",
            element: "input",
            type: "text",
          },
          {
            label: "Color",
            name: "color",
            element: "select",
            options: [
              {id: 'red', value: 'Red'},
              {id: 'green', value: 'Green'},
              {id: 'blue', value: 'Blue'},
              {id: 'orange', value: 'Orange'},
            ]
          },
        ]
      },
    ],
  },
];

function moveWidgetRight(project, widget) {
}

const widgetService = {
  widgets,
  moveWidgetRight
};

export default widgetService;
