import React from 'react';
import ReactDOM from 'react-dom/client';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
/*
import { ChakraProvider } from '@chakra-ui/react';
import { ColorModeScript } from '@chakra-ui/color-mode';
*/
import { AuthContextProvider } from './context/AuthContext';
import { ConnectionContextProvider } from './context/ConnectionContext';
import { ProjectContextProvider } from './context/ProjectContext';
import App from './App';
import { ProjectStateContextProvider } from './context/ProjectStateContext';
// import theme from './theme';

/*
Bugsnag.start({
  apiKey: '684e78157d8b7f4c451da7d35ddb34ec',
  plugins: [new BugsnagPluginReact()]
});

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React);
*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
  {/*
  <ErrorBoundary>
  */}
    <AuthContextProvider>
      <ProjectStateContextProvider>
        <ProjectContextProvider>
          <ConnectionContextProvider>
            <App />
          </ConnectionContextProvider>
        </ProjectContextProvider>
      </ProjectStateContextProvider>
    </AuthContextProvider>
  {/*
  </ErrorBoundary>
  */}
  </>
);
