const ConnectionReducer = (state, action) => {
  switch (action.type) {
    case "CONNECT": {
      return {
        connection: action.payload,
      };
    }
    case "DISCONNECT": {
      return {
        connection: null,
      };
    }
    default:
      return state;
  }
}

export default ConnectionReducer;
