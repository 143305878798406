import BasePopup from "./BasePopup";
import Button from '../Button';
import FormRow from '../form/FormRow';
import Label from '../Label';
import Input from '../Input';
import { useState, useContext, useEffect } from "react";
import { ProjectContext } from '../../context/ProjectContext';
import projectService from '../../services/project';
import { v4 as uuidv4 } from 'uuid';
import widgetService from "../../services/widget";
import Switch from "../Switch";
import WidgetInput from '../form/WidgetInput';
import { toast } from "react-toastify";
import logService from "../../services/log";
import { AuthContext } from "../../context/AuthContext";
import { getDownloadURL, getStorage, listAll, ref, uploadBytes } from 'firebase/storage';

const MediaPopup = ({ sectionId, onSave = () => {}, onClose = () => {}, visible = false}) => {
  const {project, dispatch} = useContext(ProjectContext);
  const {currentUser} = useContext(AuthContext);
  const [popupPage, setPopupPage] = useState(0);
  const [icons, setIcons] = useState([]);
  const [file, setFile] = useState(null);
  const [selected, setSelected] = useState(null);

  const storage = getStorage();
  const iconsRef = ref(storage, 'icons');

  function fetchItems() {
    listAll(iconsRef).then((res) => {
      let temp = [];
      res.items.forEach((item) => {
        console.log(item);
        getDownloadURL(item).then((url) => {
          temp.push({
            id: item._location.path,
            url
          });
          setIcons(temp);
        });
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
    fetchItems();
  }, []);

  function uploadIcon() {
    if (file == null)
      return;

    const uploadRef = ref(storage, `icons/${file.name}`);
    uploadBytes(uploadRef, file).then((snapshot) => {
      toast.success('Icon uploaded');
      fetchItems();
    });
  }

  function pageZero() {
    return (
      <div className="text-white flex flex-col gap-2">
        <input type="file" onChange={(e) => setFile(e.target.files != null && e.target.files.length ? e.target.files[0] : null)} />
        <Button onClick={() => uploadIcon()}>Upload</Button>
      </div>
    );
  }

  function pageOne() {
    return (
      <>
        {icons.length == 0 ?
          <div className="text-white">No icons uploaded</div>
        :
          <>
            <div className="grid grid-cols-3 gap-2 mb-2">
              {icons.map((item, key) =>
              <div key={key} onClick={() => setSelected(item.id)} className={"flex flex-col items-center justify-center h-[100px] p-[8px] gap-[10px] cursor-pointer border-2 rounded-lg " + (selected == item.id ? "border-orange" : "border-gray-dark")}>
                <img src={item.url} />
              </div>
              )}
            </div>
            <Button disabled={selected == null} onClick={() => onSave(selected)}>Select</Button>
          </>
        }
      </>
    );
  }

  return (
    <BasePopup>
      <div className="flex justify-between mb-2">
        <div className="flex gap-2">
          <Button type={popupPage == 0 ? "primary" : "secondary"} onClick={() => setPopupPage(0)}>Upload</Button>
          <Button type={popupPage == 1 ? "primary" : "secondary"} onClick={() => setPopupPage(1)}>Gallery</Button>
        </div>
        <Button type="danger" onClick={() => onClose()}>X</Button>
      </div>
      <div>
        {popupPage == 0 ?
          pageZero()
        : <></>}      
        {popupPage == 1 ?
          pageOne()
        : <></>}      
      </div>
    </BasePopup>
  );
}

export default MediaPopup;
