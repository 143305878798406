import { Component, createRef, useEffect, useRef, useState } from "react";
import debounce from "../../utils/debounce";

/*
const MultiLineChart = ({values = []}) => {
  const ref = useRef(null);
  const [dimensions, setDimensions] = useState({
    width: 0, 
  });
  const [points, setPoints] = useState([]);
  const [upLow, setUpLow] = useState({
    upper: null,
    lower: null
  });
  const height = 100;

  function definePoints(values) {
    if (values == null || !values.length) {
      return;
    }

    let lowest = null;
    let highest = null;
    let length = ref.current.clientWidth;
    let temp = [];
    values.map((item, itemIndex) => {
      const widthLevel = length / (item.values.length - 1);
      let innerTemp = [];

      item.values.map((value, index) => {
        if (lowest == null)
          lowest = value;
        if (highest == null)
          highest = value;

        if (value < lowest) {
          lowest = value;
        } else if (value > highest) {
          highest = value;
        }
      });
      
      const level = height / (highest - lowest);

      item.values.map((value, index) => {
        innerTemp.push({
          x: index * widthLevel,
          y: ((highest - value) * level) + 20,
        });
      });

      temp.push(innerTemp);
    });

    setPoints(temp);

    setUpLow({
      upper: highest,
      lower: lowest
    });
  }

  useEffect(() => {
    setDimensions({
      width: ref.current.clientWidth,
    });

    definePoints(values);

    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        width: window.innerWidth,
      });

      definePoints(values);
    }, 1000);

    window.addEventListener('resize', debouncedHandleResize);

    return _ => {
      window.removeEventListener('resize', debouncedHandleResize);
    }
  }, []);

  function pointsToString(num) {
    if (!points.length)
      return "";

    let response = "";
    points[num].map((point, index) => {
      if (index != 0)
        response += " ";
      response += point.x.toString() + "," + point.y.toString();
    });

    return response;
  }

  return (
    <div>
      <svg className="w-full h-[200px]" ref={ref}>
        <text x={0} y={20} fill="#ffffff">{upLow.upper}</text>
        {values.map((item, index) =>
          <>
            <text x={dimensions.width - 40} y={14 + (20 * index)} fill={item.color}>{item.values[item.values.length - 1]}</text>
            <polyline
              fill="none"
              stroke={item.color}
              strokeWidth={3}
              points={pointsToString(index)}
            />
            <circle cx={10 + (index * 120)} cy="180" r="6" fill={item.color} />
            <text x={24 + (index * 120)} y="185" fill="#ffffff">{item.label}</text>
          </>
        )}
        <text x={0} y={140} fill="#ffffff">{upLow.lower}</text>
      </svg>
    </div>
  );
}
*/

class MultiLineChart extends Component {
  state = {
    upper: null,
    lower: null,
    width: null,
    points: [],
    isRendering: false,
  };

  height = 100;

  ref = createRef();

  debouncedHandleResize = debounce(() => {
    this.setState({width: this.ref.current.clientWidth});
    this.definePoints(this.props.values);
  }, 1000);

  componentDidMount() {
    this.setState({width: this.ref && this.ref.current ? this.ref.current.clientWidth : 100});
    this.definePoints(this.props.values);

    window.addEventListener('resize', this.debouncedHandleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedHandleResize);
  }

  pointsToString(num) {
    let response = "";

    if (!this.state.points || !this.state.points.length)
      return response;

    this.state.points[num].map((point, index) => {
      if (index != 0)
        response += " ";
      response += point.x.toString() + "," + point.y.toString();
    });

    return response;
  }

  /*
  pointsToString(points) {
    if (points.length < 2)
      return;
    let response = "";
    points.map((point, index) => {
      if (index != 0)
        response += " ";
      response += point.x.toString() + "," + point.y.toString();
    });

    this.setState({pointsStr: response});
  }
  */

  /*
  definePoints(values) {
    if (values != null && values.length < 2)
      return;

    if (values != null && values.length) {
      let temp = [];
      let lowest = null;
      let highest = null;
      let length = this.ref.current.clientWidth;
      const widthLevel = length / (values.length - 1);

      values.map((value, index) => {
        if (index == 0) {
          lowest = value;
          highest = value;
          return;
        }

        if (value < lowest) {
          lowest = value;
        } else if (value > highest) {
          highest = value;
        }
      });

      this.setState({lower: lowest, upper: highest});
      
      let level = 1;
      if (highest != lowest) {
        level = this.height / (highest - lowest);
      }

      values.map((value, index) => {
        temp.push({
          x: index * widthLevel,
          y: ((highest - value) * level) + 20,
        });
      });

      this.setState({points: temp});

      this.pointsToString(temp);
    }
  }
  */

  definePoints(values) {
    if (values == null || !values.length)
      return;

    let lowest = null;
    let highest = null;
    let length = this.ref && this.ref.current ? this.ref.current.clientWidth : 200;
    let temp = [];
    values.map((item, itemIndex) => {
      const widthLevel = length / (item.values.length - 1);
      let innerTemp = [];

      item.values.map((value, index) => {
        if (lowest == null)
          lowest = value;
        if (highest == null)
          highest = value;

        if (value < lowest) {
          lowest = value;
        } else if (value > highest) {
          highest = value;
        }
      });
      
      const level = this.height / (highest - lowest);

      item.values.map((value, index) => {
        innerTemp.push({
          x: index * widthLevel,
          y: ((highest - value) * level) + 20,
        });
      });

      temp.push(innerTemp);
    });

    this.setState({points: temp});
    this.setState({upper: highest});
    this.setState({lower: lowest});
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps != this.props && !this.state.isRendering) {
      setTimeout(() => {
        this.setState({isRendering: true});
        this.definePoints(this.props.values);
        this.setState({isRendering: false});
      }, 1000);
    }
  }

  getColor(name) {
    let response = "#ffffff";

    switch(name) {
      case 'orange':
        response = "#F38A4F";
        break;
      case 'red':
        response = "#C83F3F";
        break;
      case 'blue':
        response = "#338BDC";
        break;
      case 'green':
        response = "#3BC559";
        break;
    }

    return response;
  }

  render() {
    return (
      <div>
        <svg className="w-full h-[200px]" ref={this.ref}>
          <text x={0} y={20} fill="#ffffff">{this.state.upper}</text>
          {this.props.values.map((item, index) =>
            <>
              <text className="font-future" x={this.state.width - 40} y={14 + (20 * index)} fill={this.getColor(item.color)}>{item.values[item.values.length - 1]}</text>
              <polyline
                fill="none"
                stroke={this.getColor(item.color)}
                strokeWidth={3}
                points={this.pointsToString(index)}
              />
              <circle cx={10 + (index * 120)} cy="180" r="6" fill={this.getColor(item.color)} />
              <text x={24 + (index * 120)} y="185" fill="#ffffff">{item.label}</text>
            </>
          )}
          <text className="font-future" x={0} y={140} fill="#ffffff">{this.state.lower}</text>
        </svg>
      </div>
    );
  }
}

export default MultiLineChart;
