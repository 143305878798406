import { useContext, useEffect, useState } from 'react';
import Dashboard from '../layouts/Dashboard';
import H1 from '../components/h1';
import { Link } from 'react-router-dom';
import Button from '../components/Button';
import { AuthContext } from '../context/AuthContext';
import userService from '../services/user';
import DeleteUserPopup from '../components/popup/DeleteUserPopup';

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const fetchData = async () => {
    const res = await userService.getAll();
    if (res) {
      setUsers(res);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Dashboard>
      <div className="p-[20px] w-full">
        <H1>Users</H1>

        <Link to="/users/create" className="block w-fit mt-6">
          <Button>New user</Button>
        </Link>

        <table className="mt-[30px] w-full">
          <thead>
            <tr>
              <th><div className="text-left text-lg text-bold text-white">Name</div></th>
              <th><div className="text-left text-lg text-bold text-white">E-mail</div></th>
              <th><div className="text-left text-lg text-bold text-white">Role</div></th>
              <th><div className="text-left text-lg text-bold text-white">Last login</div></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) =>
            <tr key={index}>
              <td className="py-3 text-white">
                {user.name}
              </td>
              <td className="py-3 text-white">
                {user.email}
              </td>
              <td className="py-3 text-white">
                {user.role}
              </td>
              <td className="py-3 text-white">
                {user.lastLogin && user.lastLogin !== null ? user.lastLogin.toDate().toLocaleString() : "no last login."}
              </td>
              <td className="py-3">
                <div className="flex gap-2">
                  <Link to={`/users/${user.id}/edit`}>
                    <Button type="secondary"><img className="w-[20px] h-[20px]" src="/icons/pen.svg" /></Button>
                  </Link>
                  <Button onClick={() => {setSelectedUser(user); setDeletePopup(true)}} type="danger"><img src="/icons/thrash.svg" /></Button>
                </div>
              </td>
            </tr>
            )}
          </tbody>
        </table>
        {deletePopup && selectedUser != null ?
          <DeleteUserPopup user={selectedUser} onCancel={() => setDeletePopup(false)} onDelete={() => {setDeletePopup(false); fetchData();}} />
        : <></>}
      </div>
    </Dashboard>
  );
  /*
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const toast = useToast();

  const fetchData = async () => {
    const res = await userService.getAll();
    if (res) {
      setUsers(res);
    }
    setIsLoading(false);
  };

  return (
    <DashboardLayout>
      <Flex gap="20px" alignItems="center">
        <Heading as="h1">Users</Heading>
        <Button as={Link} to="/users/create" size="sm">Create new user</Button>
      </Flex>

      <Skeleton isLoaded={!isLoading}>
        <TableContainer mt="20px">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>E-mail</Th>
                <Th>Role</Th>
                <Th>Last Login</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {users.map((user) =>
                <Tr key={user.id}>
                  <Td>{user.name}</Td>
                  <Td>{user.email}</Td>
                  <Td>{user.role}</Td>
                  <Td>{user.lastLogin && user.lastLogin !== null ? user.lastLogin.toDate().toLocaleString() : "no last login."}</Td>
                  <Td textAlign="right">
                    <Menu>
                      <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>Actions</MenuButton>
                      <MenuList>
                        <MenuItem as={Link} icon={<EditIcon />} to={`/users/${user.id}/edit`}>Edit</MenuItem>
                        <MenuItem icon={<DeleteIcon />} onClick={(event) => handleDelete(event, user)}>Delete</MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Skeleton>
    </DashboardLayout>
  );
  */
}

export default UsersPage;
