import BasePopup from "./BasePopup";
import Button from '../Button';
import FormRow from '../form/FormRow';
import Label from '../Label';
import Input from '../Input';
import { useState, useContext } from "react";
import { ProjectContext } from '../../context/ProjectContext';
import projectService from '../../services/project';
import { v4 as uuidv4 } from 'uuid';
import widgetService from "../../services/widget";
import Switch from "../Switch";
import WidgetInput from '../form/WidgetInput';
import { toast } from "react-toastify";
import logService from "../../services/log";
import { AuthContext } from "../../context/AuthContext";

const NewWidgetPopup = ({ sectionId, onSave = () => {}, onClose = () => {}, visible = false}) => {
  const {project, dispatch} = useContext(ProjectContext);
  const {currentUser} = useContext(AuthContext);
  const [selectedType, setSelectedType] = useState(null);
  const [page, setPage] = useState(0);
  const [inputs, setInputs] = useState([]);

  function handleSave() {
    let okayToSave = true;
    let temp = project;
    temp.layout.map((section) => {
      if (section.uuid === sectionId) {
        let fields = [];
        inputs.map((input, index) => {
          if (index == 0)
            return;
          fields.push(input);
          /*
          if (input.value == null || input.value == "")
            okayToSave = false;
            */
        });

        let item = {
          uuid: uuidv4(),
          title: inputs[0].value,
          visible: inputs[1].value,
          type: selectedType,
          fields: fields,
        };

        if (okayToSave)
          section.children.push(item);
      }
    });

    if (!okayToSave) {
      toast.error('Please fill the empty fields');
      return;
    }

    dispatch({type: "SET", payload: temp});

    projectService.saveProject(project);

    logService.log(`widget ${inputs[0].value} created by ${currentUser.name}`, project.id, currentUser.uid);

    onSave();
  }

  function handleNext() {
    if (selectedType == null) {
      return;
    }

    widgetService.widgets.map((widget) => {
      if (widget.value == selectedType) {
        widget.inputs.map((input) => 
          setInputs(inputs => [...inputs, {...input, value: input.value ? input.value : ""}])
        );
      }
    });

    setPage(1);
  }

  function handleBack() {
    setPage(0);
    setInputs([]);
  }

  function screenOne() {
    return (
      <>
        <p className="text-white text-lg py-3">Select widget type</p>

        <div className="grid grid-cols-3 gap-[10px] pb-3">

          {widgetService.widgets.map((type, index) =>
            <div onClick={() => setSelectedType(type.value)} key={index} className={"flex flex-col items-center justify-center h-[100px] p-[8px] bg-[#000000] gap-[10px] cursor-pointer border-4 rounded-lg " + (selectedType == type.value ? "border-orange" : "border-transparent opacity-80")}>
              <img className="w-[32px]" src={type.icon} />
              <div className="text-white">{type.title}</div>
            </div>
          )}
  
        </div>

        <div className="flex gap-2">
          <Button type="primary" onClick={handleNext}>Next</Button>
          <Button type="secondary" onClick={onClose}>Close</Button>
        </div>
      </>
    );
  }

  function handleInputChange(value, input) {
    let temp = inputs;
    temp.map((item) => {
      if (item.name == input.name) {
        item.value = value;
      }
    });
    setInputs(temp);
  }

  function screenTwo() {
    return (
      <>
        <div className="my-6 flex flex-col gap-4">
          {inputs && inputs.length && inputs.map((input, index) =>
            <WidgetInput input={input} key={index} onChange={(e) => handleInputChange(e, input)} />
          )}
        </div>
        
        <div className="flex gap-2">
          <Button type="primary" onClick={handleSave}>Create</Button>
          <Button type="secondary" onClick={handleBack}>Back</Button>
          <Button type="secondary" onClick={onClose}>Close</Button>
        </div>
      </>
    );
  }

  return (
    <BasePopup>
      <div>
        <div className="text-[20px] text-white font-bold">New {selectedType && page != 0 ? selectedType : "" } Widget</div>

        { page == 0 ?
          screenOne()
        : <></>}

        { page == 1 ?
          screenTwo()
        : <></>}

      </div>

    </BasePopup>
  );
}

export default NewWidgetPopup;
