import { useContext, useEffect, useState } from 'react';
import WidgetBase from './WidgetBase';
import mqttService from '../../services/mqtt';
import { ConnectionContext } from '../../context/ConnectionContext';
import { ProjectContext } from '../../context/ProjectContext';

const WidgetText = ({widget, sectionId}) => {
  const { connection } = useContext(ConnectionContext);
  const { project, dispatch } = useContext(ProjectContext);
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (connection && connection.connected) {
      mqttService.subscribe(connection.client, project.identifier + widget.fields[1].value);
      mqttService.listenTopic(connection.client, project.identifier + widget.fields[1].value, (message) => {
        setValue(message);
      });
    }
  }, [connection]);

  return (
    <WidgetBase widget={widget} sectionId={sectionId}>
      <div className="font-seven_segment text-orange text-[48px] text-center">{value != null ? value : "-"}</div>
    </WidgetBase>
  );
}

export default WidgetText;
