import { useContext, useEffect, useState } from 'react';
import WidgetBase from './WidgetBase';
import mqttService from '../../services/mqtt';
import { ConnectionContext } from '../../context/ConnectionContext';
import { ProjectContext } from '../../context/ProjectContext';

const WidgetLed = ({widget, sectionId}) => {
  const { connection } = useContext(ConnectionContext);
  const { project, dispatch } = useContext(ProjectContext);
  const gridClasses = "grid mt-2 gap-2 " + (widget.fields[1].value.length > 1 ? "grid-cols-2" : "grid-cols-1");
  const indicatorBaseClasses = "text-center px-3 py-2 rounded-lg font-bold text-black ";
  const [states, setStates] = useState([]);

  useEffect(() => {
    let temp = [];
    widget.fields[1].value.map((indicator, index) => {
      temp.push('off');
    });
    setStates(temp);
  }, []);

  useEffect(() => {
    if (connection && connection.connected) {
      widget.fields[1].value.map((indicator, index) => {
        mqttService.subscribe(connection.client, project.identifier + indicator.topic);
        mqttService.listenTopic(connection.client, project.identifier + indicator.topic, (message) => {
          let newStates = states;
          newStates[index] = message == indicator.on ? 'on' : 'off';
          setStates(newStates);
          dispatch({type: 'SET', payload: project});
        });
      });
    }
  }, [connection]);

  function indicatorBg(indicator, index) {
    if (states[index] == 'on')
      return "bg-" + indicator.color;
    else
      return "bg-gray";
  }

  return (
    <WidgetBase widget={widget} sectionId={sectionId}>
      <div className={gridClasses}>
      {widget.fields[1].value.map((indicator, index) =>
        <div key={index} className={indicatorBaseClasses + indicatorBg(indicator, index)}>{indicator.label}</div>
      )}
      </div>
    </WidgetBase>
  );
}

export default WidgetLed;
