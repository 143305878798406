import BasePopup from "./BasePopup";
import Button from '../Button';
import FormRow from '../form/FormRow';
import Label from '../Label';
import Input from '../Input';
import { useState, useContext, useEffect } from "react";
import { ProjectContext } from '../../context/ProjectContext';
import projectService from '../../services/project';
import widgetService from "../../services/widget";
import WidgetInput from "../form/WidgetInput";
import logService from "../../services/log";
import { AuthContext } from "../../context/AuthContext";
import { ConnectionContext } from "../../context/ConnectionContext";
import mqttService from "../../services/mqtt";

const EditWidgetPopup = ({ widget, sectionId, onSave = () => {}, onClose = () => {}}) => {
  const [inputs, setInputs] = useState([]);
  const [title, setTitle] = useState(widget.title);
  const {project, dispatch} = useContext(ProjectContext);
  const {currentUser} = useContext(AuthContext);
  const connectionContext = useContext(ConnectionContext);

  useEffect(() => {
    // fill the inputs
    widget.fields.map((field) => {
      setInputs(inputs => [...inputs, field])
    });

  }, []);

  function handleSave() {
    let temp = project;
    temp.layout.map((section) => {
      if (section.uuid === sectionId) {
        section.children.map((val) => {
          if (val.uuid === widget.uuid) {
            val.title = title;
            val.fields.map((field, index) => {
              field.value = inputs[index].value;
            });
          }
        });
      }
    });

    dispatch({type: "SET", payload: temp});

    projectService.saveProject(project);

    logService.log(`widget ${title} updated by ${currentUser.name}`, project.id, currentUser.uid);

    connectionContext.dispatch({type: "DISCONNECT"});
    console.log("disconnected from broker");
  
    const client = mqttService.getClient(project.brokerFullUrl);
    const payload = {
      connected: true,
      brokerFullUrl: project.brokerFullUrl,
      client: client
    };
    connectionContext.dispatch({type: "CONNECT", payload: payload});
    console.log("connected to broker");

    onSave();
  }

  function handleInputChange(value, input) {
    let temp = inputs;
    temp.map((item) => {
      if (item.name == input.name) {
        item.value = value;
      }
    });
    setInputs(temp);
  }

  return (
    <BasePopup>
      <div>
        <div className="text-[20px] text-white font-bold">Edit Widget</div>
      </div>

      <div className="my-6 flex flex-col gap-4">
        <FormRow>
          <Label>Title</Label>
          <Input defaultValue={widget.title} type="text" onChange={(e) => setTitle(e.target.value)} />
        </FormRow>
        {inputs && inputs.length && inputs.map((input, index) =>
          <WidgetInput input={input} key={index} onChange={(e) => handleInputChange(e, input)} />
        )}
      </div>
      
      <div className="flex gap-2">
        <Button type="primary" onClick={handleSave}>Save</Button>
        <Button type="secondary" onClick={onClose}>Close</Button>
      </div>
    </BasePopup>
  );
}

export default EditWidgetPopup;
