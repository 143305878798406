import { useContext, useState } from "react";
import { ProjectContext } from "../../context/ProjectContext";
import { Link, useLocation, useParams } from "react-router-dom";
import NewSectionPopup from "../popup/NewSectionPopup";
import Button from "../Button";

const ProjectSidebar = () => {
  const {project} = useContext(ProjectContext);
  const [newSectionPopup, setNewSectionPopup] = useState(false);
  const { id } = useParams();
  const location = useLocation();

  if (!project || !project.layout) {
    return <></>;
  }

  return (
    <div>
      <Link to={"/projects/" + id } className="block text-white font-semibold py-[12px] px-[20px] hover:bg-gray-dark transition-colors">Dashboard</Link>
      <div>
        {location.pathname === "/projects/" + id ?
        project.layout.map((section, index) =>
          <a href={"#" + section.uuid} className="block py-[10px] px-[20px] text-white bg-[#212121]" key={index}>{section.title}</a>
        )
        :<></>}
        {location.pathname === "/projects/" + id ?
        <div className="py-[10px] px-[20px] bg-[#212121]">
          <Button type="secondary" onClick={() => setNewSectionPopup(true)}>New section</Button>
        </div> : <></>}

        {newSectionPopup ?
          <NewSectionPopup onSave={() => setNewSectionPopup(false)} onClose={() => setNewSectionPopup(false)} />
        : <></>}
      </div>
      <Link to={"/projects/" + id + "/logs"} className="block text-white font-semibold py-[12px] px-[20px] hover:bg-gray-dark transition-colors">Logs</Link>
      <Link to={"/projects/" + id + "/edit"} className="block text-white font-semibold py-[12px] px-[20px] hover:bg-gray-dark transition-colors">Project settings</Link>
    </div>
  );
}

export default ProjectSidebar;
