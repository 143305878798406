import { setDoc, collection, getDoc, doc, getDocs, query, where, serverTimestamp, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';

function saveProject(project) {
  return new Promise(async (resolve, reject) => {
    try {
      const temp = {
        ...project,
        timeStamp: serverTimestamp(),
      };
      await setDoc(doc(db, "projects", project.id), temp);
      localStorage.setItem('project', JSON.stringify(temp));
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });
}

function deleteProject(project) {
  return new Promise(async (resolve, reject) => {
    try {
      await deleteDoc(doc(db, "projects", project.id));
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });
}

function getWithId(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const docSnap = await getDoc(doc(db, "projects", id));
      resolve({id: id, ...docSnap.data()});
    } catch (err) {
      reject(err);
    }
  });
}

function getMyProjects(currentUser) {
  return new Promise(async (resolve, reject) => {
    let list = [];
    try {
      const querySnapshot = await getDocs(collection(db, "projects"));
      querySnapshot.forEach((doc) => {
        if (currentUser.projects.includes(doc.id)) {
          list.push({id: doc.id, ...doc.data()});
        }
      });
      resolve(list);
    } catch (err) {
      reject(list);
    }
  });
}

function getWithAttribute(attribute, value) {
  return new Promise(async (resolve, reject) => {
    let project = null;
    try {
      const q = query(collection(db, "projects"), where(attribute, '==', value));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        project = doc.data();
      });
      resolve(project);
    } catch (err) {
      reject(err);
    }
  });
}

function getAll() {
  return new Promise(async (resolve, reject) => {
    let list = [];
    try {
      const querySnapshot = await getDocs(collection(db, "projects"));
      querySnapshot.forEach((doc) => {
        list.push({id: doc.id, ...doc.data()});
      });
      resolve(list);
    } catch (err) {
      reject(list);
    }
  });
}

function setAutoConnect(project, value) {
  return new Promise(async (resolve, reject) => {
    try {
      let temp = project;
      temp.autoConnect = value;
      saveProject(temp);
      resolve();
    } catch (err) {
      reject();
    }
  });
}

const projectService = {
  getAll,
  getWithAttribute,
  getWithId,
  getMyProjects,
  saveProject,
  setAutoConnect,
  deleteProject
};

export default projectService;
