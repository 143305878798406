import BasePopup from "./BasePopup";
import Button from '../Button';
import userService from "../../services/user";
import { toast } from "react-toastify";

const DeleteUserPopup = ({ user, onDelete = () => {}, onCancel = () => {} }) => {
  function handleDelete() {
    userService.deleteWithId(user.id).then(() => {
      toast.success('User deleted');
      onDelete();
    }).catch((err) => {
      toast.error(err);
      onDelete();
    });
  }

  return (
    <BasePopup>
      <div>
        <div className="text-[20px] text-white font-bold">Delete User</div>
      </div>

      <p className="text-white py-3">Are you sure about deleting {user.name} user ? This action is not reversable!</p>
      
      <div className="flex gap-2">
        <Button type="danger" onClick={handleDelete}>Delete</Button>
        <Button type="secondary" onClick={onCancel}>Cancel</Button>
      </div>
    </BasePopup>
  );
}

export default DeleteUserPopup;
