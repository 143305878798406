import { useContext, useState, useEffect } from "react";
import Switch from "./Switch";
import Button from "./Button";
import { ProjectStateContext } from "../context/ProjectStateContext";
import { ConnectionContext } from "../context/ConnectionContext";
import { Link, useParams } from "react-router-dom";
import { ProjectContext } from "../context/ProjectContext";
import mqttService from '../services/mqtt';
import projectService from "../services/project";

const ProjectHeader = ({title}) => {
  const [connectionSettingsPopup, setConnectionSettingsPopup] = useState(false);
  const {projectState, dispatch} = useContext(ProjectStateContext);
  const project = useContext(ProjectContext);
  const connection = useContext(ConnectionContext);
  const [isDisplayMode, setIsDisplayMode] = useState(projectState == "display");
  const { id } = useParams();

  function handleModeChange() {
    dispatch({type: 'SET', payload: !isDisplayMode ? "display" : "edit"});
    setIsDisplayMode(!isDisplayMode);
  }

  const subscribeToTopics = (res, client) => {
    let topicsToSubscribe = [`${project.project.identifier}get`, `${project.project.identifier}input`];
    /*
    res.layout.map((section) => {
      section.children.map((widget) => {
        if (widget.more.topic && !topicsToSubscribe.includes(project.project.identifier + widget.more.topic)) {
          topicsToSubscribe.push(project.project.identifier + widget.more.topic);
        }
        if (widget.more.replyTopic && !topicsToSubscribe.includes(project.project.identifier + widget.more.replyTopic)) {
          topicsToSubscribe.push(project.project.identifier + widget.more.replyTopic);
        }
        return true;
      });
      return true;
    });
    */

    topicsToSubscribe.map((topic) => {
      mqttService.subscribe(client, topic);
      console.log(`subscribed to ${topic}`);
      return true;
    });
  }

  const unsubscribeToTopics = (res) => {
    let topicsToUnsubscribe = [`${project.project.identifier}get`, `${project.project.identifier}input`];
    /*
    res.layout.map((section) => {
      section.children.map((widget) => {
        if (widget.more.topic && !topicsToUnsubscribe.includes(project.project.identifier + widget.more.topic)) {
          topicsToUnsubscribe.push(project.project.identifier + widget.more.topic);
        }
        if (widget.more.replyTopic && !topicsToUnsubscribe.includes(project.project.identifier + widget.more.replyTopic)) {
          topicsToUnsubscribe.push(project.project.identifier + widget.more.replyTopic);
        }
        return true;
      });
      return true;
    });
    */

    topicsToUnsubscribe.map((topic) => {
      mqttService.unsubscribe(connection.connection.client, topic);
      console.log(`unsubscribed from ${topic}`);
      return true;
    });
  }
  
  const connect = () => {
    const client = mqttService.getClient(project.project.brokerFullUrl);
    subscribeToTopics(project.project, client);
    const payload = {
      connected: true,
      brokerFullUrl: project.project.brokerFullUrl,
      client: client
    };
    connection.dispatch({type: "CONNECT", payload: payload});
    console.log("connected to broker");
    return payload;
  }

  const disconnect = () => {
    unsubscribeToTopics(project.project);
    connection.dispatch({type: "DISCONNECT"});
    mqttService.closeConnection(connection.connection.client);
    console.log("disconnected from broker");
  }

  useEffect(() => {
    if (!connection.connection && project.project && project.project.autoConnect) {
      connect();
    }
  }, [project.project]);

  useEffect(() => {
    if (connection.connection && connection.connection.connected) {
      let temp1 = project.project;
      let temp2 = connection.connection;

      return function cleanup() {
        if (!temp2 || !temp2.client || !temp1 || !temp1.layout) {
          return;
        }
        let topicsToUnsubscribe = ["get"];
        temp1.layout.map((section) => {
          section.children.map((widget) => {
            if (widget.topic && !topicsToUnsubscribe.includes(widget.topic)) {
              topicsToUnsubscribe.push(widget.topic);
            }
          });
        });

        topicsToUnsubscribe.map((topic) => {
          mqttService.unsubscribe(temp2.client, topic);
          console.log(`unsubscribed from ${topic}`);
        });
        dispatch({type: "DISCONNECT"});
        console.log("disconnected from broker");
      }
    }
  }, [connection.connection]);

  function handleAutoConnectChange(value) {
    projectService.setAutoConnect(project.project, value);
  }

  return (
    <div className="fixed w-[calc(100%-220px)] z-[999] flex justify-between items-center bg-black-light border-b border-gray">
      <div className="text-lg font-bold text-white pl-[20px]">{project.project.name}</div>
      <div className="flex items-center gap-[12px]">
        <div className={"text-[13px] " + (isDisplayMode ? "text-white" : "text-gray")}>Display mode</div>
        <Switch defaultValue={isDisplayMode} onClick={handleModeChange} />
        <div className={"text-[13px] " + (!isDisplayMode ? "text-white" : "text-gray")}>Edit mode</div>
      </div>
      {connection.connection && connection.connection.connected ? 
      <div className="flex">
        <div className="border-l-0 border-t-[60px] border-b-0 border-r-[60px] border-b-transparent border-r-green border-t-transparent border-l-transparent"></div>
        <div className="h-[60px] pr-[12px] flex items-center bg-green relative cursor-pointer" onClick={() => setConnectionSettingsPopup(!connectionSettingsPopup)}>
          <div className="text-white font-bold select-none">
            CONNECTED
          </div>
        </div>
      </div>
      :
      <div className="flex">
        <div className="border-l-0 border-t-[60px] border-b-0 border-r-[60px] border-b-transparent border-r-red border-t-transparent border-l-transparent"></div>
        <div className="h-[60px] pr-[12px] flex items-center bg-red relative cursor-pointer" onClick={() => setConnectionSettingsPopup(!connectionSettingsPopup)}>
          <div className="text-white font-bold select-none">
            DISCONNECTED
          </div>
        </div>
      </div>
      }
      <div className={"absolute z-[999] w-[250px] top-[60px] right-0 bg-black-light border border-gray p-[10px] " + (connectionSettingsPopup ? "" : "hidden")}>
        <div className="flex flex-col gap-3">
          <div className="flex justify-between">
            <span className="text-white">Auto-connect</span>
            <Switch defaultValue={project.project.autoConnect} onClick={handleAutoConnectChange} />
          </div>
          <div className="flex justify-between">
            {connection.connection && connection.connection.connected ? 
            <Button rounded={false} type="danger" onClick={disconnect}>Disconnect</Button>
            :
            <Button rounded={false} type="primary" onClick={connect}>Connect</Button>
            }
            <Link to={"/projects/" + id + "/edit"}>
              <Button rounded={false} type="secondary">
                <img src="/icons/cog.svg" />
              </Button>
            </Link>
          </div>
        </div> 
      </div>
    </div>
  );
}

export default ProjectHeader;
