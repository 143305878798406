import { useContext, useEffect, useState } from "react";
import { ConnectionContext } from "../../context/ConnectionContext";
import { ProjectContext } from "../../context/ProjectContext";
import { ProjectStateContext } from "../../context/ProjectStateContext";
import mqttService from "../../services/mqtt";
import BarChart from "../chart/BarChart";
import WidgetBase from "./WidgetBase";

const WidgetBar = ({ widget, sectionId }) => {
  const {project, dispatch} = useContext(ProjectContext);
  //const {dispatch} = useContext(ProjectStateContext);
  const [values, setValues] = useState(null);
  const connectionContext = useContext(ConnectionContext);
  const items = widget.fields && widget.fields.length > 1 ? widget.fields[1].value : [];

  useEffect(() => {
    let temp = [];
    items.map((item) => {
      temp.push(0);
    });
    setValues(temp);
  }, []);

  useEffect(() => {
    if (connectionContext.connection && connectionContext.connection.connected) {
      items.map((item, index) => {
        mqttService.subscribe(connectionContext.connection.client, project.identifier + item.topic);
        mqttService.listenTopic(connectionContext.connection.client, project.identifier + item.topic, (message) => {
          const intMessage = parseInt(message);
          if (intMessage >= parseInt(item['min-value']) && intMessage <= parseInt(item['max-value'])) {
            let temp = values;
            if (temp.length < index + 1)
              return;
            temp[index] = message;
            setValues(temp);
            dispatch({type: 'RENDER'}); 
          }
        });
      });
    }
  }, [connectionContext.connection]);

  function getColor(name) {
    let response = "#ffffff";

    switch(name) {
      case 'orange':
        response = "#F38A4F";
        break;
      case 'red':
        response = "#C83F3F";
        break;
      case 'blue':
        response = "#338BDC";
        break;
      case 'green':
        response = "#3BC559";
        break;
    }

    return response;
  }

  return (
    <WidgetBase widget={widget} sectionId={sectionId}>
      <div className="flex flex-col gap-2">
      {items.map((item, index) =>
        <>
        {index != 0 ?
          <div className="w-full border border-dashed border-gray"></div>
        : <></>}
        <div>
          <div className="text-white">{item.label}</div>
          <BarChart min={item['min-value']} max={item['max-value']} value={values != null ? values[index] : 0} unit={item['unit']} color={item.color ? getColor(item.color) : "#F38A4F"} />
        </div>
        </>
      )}
      </div>
    </WidgetBase>
  );
}

export default WidgetBar;
