import { useEffect, useState } from "react";
import debounce from "../../utils/debounce";

const GaugeChart = ({ percent = 0, min = 0, max = 100, color, uid = Math.floor(Math.random() * 10000) }) => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  });
  const width = 180;
  const height = 90;
  const letterWidth = 8;

  function calcRealPercent() {
    let val;

    if (percent >= max) {
      val = 100;
    } else if (percent <= min) {
      val = 0;
    } else {
      val = (percent / (max - min));
    }

    return val;
  }

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        width: window.innerWidth,
      });
    }, 1000);

    window.addEventListener('resize', debouncedHandleResize);

    return _ => {
      window.removeEventListener('resize', debouncedHandleResize);
    }
  }, []);

  function fullCircle(radius) {
    const strokeWidth = radius * 0.2;
    const innerRadius = radius - strokeWidth / 2;

    const circumference = innerRadius * 2 * Math.PI;
    const arc = circumference * (180 / 360);
    const dashArray = `${arc} ${circumference}`;
    const transform = `rotate(180, ${height}, ${height})`;

    return (
      <circle
        cx={height}
        cy={height}
        fill="transparent"
        r={innerRadius}
        stroke={color}
        strokeWidth={2}
        strokeDasharray={dashArray}
        transform={transform}
      /> 
    );
  }

  function bgBarsClip() {
    const val = calcRealPercent();

    let string = "";
    let translate = "";

    if (val <= 25) {
      let l = (val / 45) * 90 * 2;
      string = `M0 ${l}H${height}L0 0Z`;
      if (l < 100)
        translate = `translate(0,${height - l})`;
    } else if (val > 25 && val < 50) {
      let l = (val - 25) * 4;
      string = `M0 ${height}H${height}L${l} 0H0Z`;
    } else if (val == 50) {
      string = `M0 ${height}L0 0H${height}l0 ${height}Z`;
    } else if(val > 50 && val < 75) {
      const leftPadding = (width / 2);
      let l = leftPadding + (val - 50) * 4;
      string = `M${leftPadding} ${height}L${l} 0H${leftPadding}Z`;
    } else if (val >= 75 && val < 100) {
      const leftPadding = (width / 2);
      let l = (val - 75) * 4;
      string = `M${leftPadding} ${height}L${leftPadding} 0H${width}l0 ${l}Z`;
    } else if (val >= 100) {
      string = `M0 ${width}L0 0H${width}l0 ${width}Z`;
    }

    return (
      <clipPath id={`bars-${uid}`}>
        {val > 50 ?
        <path d={`M0 ${height}L0 0H${height}l0 ${height}Z`} transform={translate} />
        : <></>}
        <path d={string} transform={translate} />
      </clipPath>
    );
  }

  function bgBars() {
    return (
      <>
        <g
          id="fill-path"
          transform="translate(1,3)"
        >
        <path d="M28.7891 24.1992L26.1992 26.7891L47.8594 47.5547L49.5547 45.8594L28.7891 24.1992Z"/>
        <path d="M90.832 0.0195312H87.1719L87.793 30.0117L90.207 30.0039L90.832 0.0195312Z"/>
        <path d="M81.0977 0.355469L77.457 0.757812L81.4062 30.5L83.8008 30.2305L81.0977 0.355469Z"/>
        <path d="M71.4414 1.77344L67.866 2.58667L75.1367 31.7032L77.4648 31.1641L71.4414 1.77344Z"/>
        <path d="M61.9922 4.27344L58.5352 5.48438L69.0312 33.5938L71.3008 32.7969L61.9922 4.27344Z"/>
        <path d="M52.8906 7.82812L49.5938 9.41797L63.1992 36.1484L65.3594 35.1133L52.8906 7.82812Z"/>
        <path d="M44.2539 12.3672L41.168 14.3203L57.6758 39.3672L59.6992 38.0898L44.2539 12.3672Z"/>
        <path d="M36.1875 17.8594L33.3281 20.1328L52.5391 43.1875L54.418 41.6797L36.1875 17.8594Z"/>
        <path d="M100.543 0.757921L96.9102 0.351562L94.207 30.2383L96.5938 30.5039L100.543 0.757921Z"/>
        <path d="M110.133 2.58583L106.563 1.77729L100.531 31.1602L102.867 31.6914L110.133 2.58583Z"/>
        <path d="M119.465 5.48441L116.008 4.2773L106.703 32.8008L108.961 33.5859L119.465 5.48441Z"/>
        <path d="M128.41 9.41405L125.106 7.8243L112.645 35.1211L114.805 36.1641L128.41 9.41405Z"/>
        <path d="M136.848 14.3127L133.746 12.3829L118.293 38.0937L120.332 39.3713L136.848 14.3127Z"/>
        <path d="M144.668 20.1444L141.813 17.8555L123.59 41.6874L125.469 43.1913L144.668 20.1444Z"/>
        <path d="M151.81 26.7769L149.211 24.2032L128.449 45.8516L130.148 47.551L151.81 26.7769Z"/>
        <path d="M158.145 34.1874L155.859 31.332L132.816 50.5313L134.313 52.4101L158.145 34.1874Z"/>
        <path d="M163.629 42.2577L161.676 39.1601L136.629 55.6718L137.91 57.7031L163.629 42.2577Z"/>
        <path d="M168.176 50.8945L166.586 47.5937L139.848 61.1954L140.883 63.3594L168.176 50.8945Z"/>
        <path d="M171.726 59.9925L170.515 56.5312L142.414 67.039L143.207 69.3008L171.726 59.9925Z"/>
        <path d="M174.228 69.4355L173.406 65.8631L144.305 73.1328L144.832 75.4687L174.228 69.4355Z"/>
        <path d="M175.652 79.0937L175.238 75.457L145.5 79.4023L145.77 81.7891L175.652 79.0937Z"/>
        <path d="M22.1283 31.345L19.843 34.2073L43.6854 52.4122L45.1845 50.5383L22.1283 31.345Z"/>
        <path d="M2.00288 85.1708L2.02167 88.8263L32.0156 88.207L32.0078 85.7891L2.00288 85.1708Z"/>
        <path d="M2.76038 75.4596L2.35547 79.0898L32.2386 81.7964L32.5003 79.3979L2.76038 75.4596Z"/>
        <path d="M4.58216 65.8632L3.77345 69.4492L33.1641 75.4688L33.6914 73.1367L4.58216 65.8632Z"/>
        <path d="M7.48448 56.5407L6.27963 59.9958L34.8008 69.3008L35.5898 67.0469L7.48448 56.5407Z"/>
        <path d="M11.4219 47.5938L9.82807 50.8982L37.1131 63.3591L38.1561 61.1989L11.4219 47.5938Z"/>
        <path d="M16.3202 39.1601L14.375 42.2538L40.1016 57.707L41.3672 55.6719L16.3202 39.1601Z"/>
        <path d="M175.983 88.8328L175.98 85.1719L145.995 85.7988L145.995 88.2026L175.983 88.8328Z"/>
        </g>
        <use href="#fill-path" fill="#818181" />
        <use clipPath={`url(#bars-${uid})`} href="#fill-path" fill={color} />
      </>
    );
  }

  return (
    <div>
      <svg height={height + 20} width={height * 2} className="mx-auto">
        <defs>
        {bgBarsClip()}
        </defs>
        {fullCircle(100)}
        {fullCircle(60)}
        {bgBars()}
        <line x1={0} y1={height - 1} x2={(height / 2) - 10} y2={height - 1} stroke={color} strokeWidth={2} />
        <line x1={width - ((height / 2) - 10)} y1={height - 1} x2={(height * 2) + 10} y2={height - 1} stroke={color} strokeWidth={2} />
        <text className="font-future" x={(width / 2) - (percent.toString().length * letterWidth)} y={height * 0.9} fontSize={20} fill="#ffffff">{percent}</text>
        <text className="font-future" x={0} y={height + 20} fill="#ffffff" fontSize={16}>{min}</text>
        <text className="font-future" x={width - 36} y={height + 20} fill="#ffffff" fontSize={16}>{max}</text>
      </svg>
    </div>
  );
}

export default GaugeChart;
