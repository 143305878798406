import { useContext, useEffect, useState } from "react";
import WidgetBase from "./WidgetBase";
import { ConnectionContext } from "../../context/ConnectionContext";
import { ProjectContext } from "../../context/ProjectContext";
import mqttService from '../../services/mqtt';

const WidgetNumber = ({ widget, sectionId }) => {
  function fillValues(widget) {
    let temp = [];
    widget.fields[1].value.map(() => {
      temp.push(0);
    });
    return temp;
  }

  const [values, setValues] = useState(fillValues(widget));
  const { connection } = useContext(ConnectionContext);
  const { project, dispatch } = useContext(ProjectContext);

  useEffect(() => {
    setValues(fillValues(widget));
    if (connection && connection.connected) {
      widget.fields[1].value.map((item, key) => {
        mqttService.subscribe(connection.client, project.identifier + item.topic);
        mqttService.listenTopic(connection.client, project.identifier + item.topic, (message) => {
          const intMessage = parseInt(message);
          if (intMessage >= parseInt(item['min-value']) && intMessage <= parseInt(item['max-value'])) {
            let temp = values;
            if (temp.length < key + 1)
              return;
            temp[key] = intMessage;
            setValues(temp);
            dispatch({type: 'RENDER'}); 
          }
        });
      });
    }
  }, [connection]);

  return (
    <WidgetBase widget={widget} sectionId={sectionId}>
      <div className="flex flex-col gap-2">
      {widget.fields[1].value.map((item, index) =>
        <>
        {index != 0 ?
          <div className="w-full border border-dashed border-gray"></div>
        : <></>}
        <div className="flex items-center justify-between">
          <div className="text-white">{item.label}</div>
          <div className="font-future text-orange">{values[index]}<span className="text-white">{item.unit}</span></div>
          <div className="flex flex-col gap-2">
            <div className="font-future text-white">{item['max-value']}{item.unit}</div>
            <div className="font-future text-white">{item['min-value']}{item.unit}</div>
          </div>
        </div>
        </>
      )}
      </div>
    </WidgetBase>
  );
}

export default WidgetNumber;
