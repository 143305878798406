import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const Sidebar = ({ inside }) => {
  const {currentUser, dispatch} = useContext(AuthContext);
  const navigate = useNavigate();

  function handleLogout() {
    dispatch({type: "LOGOUT"});
    navigate("/login");
  }

  return (
    <div className="flex flex-col min-w-[220px] w-[220px] bg-black-light border-r border-gray">

      <div className="h-[100px] flex items-center py-[14px] px-[20px] border-b border-gray">
        <Link to="/" className="h-full">
          <img src="/logo.png" className="h-full" />
        </Link>
      </div>

      <div className="flex-1 flex flex-col justify-between">

        <div>
          <div>
            {currentUser.role == "admin" ?
            <Link to="/users" className="flex gap-2 text-white font-semibold py-[12px] px-[20px] hover:bg-gray-dark transition-colors">
              <img src="/icons/group.svg" />
              Users
            </Link>
            : <></>}
            <Link to="/" className="flex gap-2 text-white font-semibold py-[12px] px-[20px] hover:bg-gray-dark transition-colors">
              <img src="/icons/arrow-left.svg" />
              Go to projects
            </Link>
          </div>
          {inside}
        </div>

        <div className="flex flex-col">
          <Link to="/settings" className="transition-all duration-200 cursor-pointer font-bold text-white p-[20px] hover:bg-gray-dark">Settings</Link>
          {/*
          <div className="transition-all duration-200 cursor-pointer font-bold text-white p-[20px] hover:bg-gray-dark">Documentation</div>
          */}
          <div onClick={handleLogout} className="transition-all duration-200 cursor-pointer bg-[#1F1F1F] hover:bg-gray-dark font-bold text-white p-[20px]">Logout</div>
        </div>

      </div>

    </div>
  );
}

export default Sidebar;
