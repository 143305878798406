import BasePopup from "./BasePopup";
import Button from '../Button';
import { useContext } from "react";
import { ProjectContext } from "../../context/ProjectContext";
import projectService from "../../services/project";
import logService from "../../services/log";
import { AuthContext } from "../../context/AuthContext";

const DeleteWidgetPopup = ({ sectionId, widget, onDelete = () => {}, onCancel = () => {} }) => {
  const {project, dispatch} = useContext(ProjectContext);
  const {currentUser} = useContext(AuthContext);

  function handleDelete() {
    let temp = project;
    temp.layout.map((section) => {
      if (section.uuid == sectionId) {
        section.children.splice(section.children.indexOf(widget), 1);
      }
    });

    dispatch({type: "SET", payload: temp});

    projectService.saveProject(project);

    logService.log(`widget ${widget.title} deleted by ${currentUser.name}`, project.id, currentUser.uid);

    onDelete();
  }

  return (
    <BasePopup>
      <div>
        <div className="text-[20px] text-white font-bold">Delete Widget</div>
      </div>

      <p className="text-white py-3">Are you sure about deleting widget "{widget.title}" ? This action is not reversable!</p>
      
      <div className="flex gap-2">
        <Button type="danger" onClick={handleDelete}>Delete</Button>
        <Button type="secondary" onClick={onCancel}>Cancel</Button>
      </div>
    </BasePopup>
  );
}

export default DeleteWidgetPopup;
