import { useState } from "react";
import NewWidgetPopup from "../popup/NewWidgetPopup";

const WidgetNew = ({ sectionId }) => {
  const [popupOpen, setPopupOpen] = useState(false);

  return (
    <>
      <div className="hover:bg-gray-dark transition-colors flex items-center justify-center h-[75px] p-[6px] cursor-pointer bg-black-light border border-gray rounded-lg" onClick={() => setPopupOpen(true)}>
        <img src="/icons/plus-widget.svg" /> 
      </div>

      {popupOpen ?
        <NewWidgetPopup sectionId={sectionId} onSave={() => setPopupOpen(false)} onClose={() => setPopupOpen(false)} />
      : <></>}
    </>
  );
}

export default WidgetNew;
