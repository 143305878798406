import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const app = initializeApp({
  // development
  /*
  apiKey: "AIzaSyBGKpf5p71srn0zvsakdxGPo79VU2aJyhk",
  authDomain: "mqtt-test-project-76f9c.firebaseapp.com",
  projectId: "mqtt-test-project-76f9c",
  storageBucket: "mqtt-test-project-76f9c.appspot.com",
  messagingSenderId: "191738994731",
  appId: "1:191738994731:web:7829970c0c687ae8430a6e"
  */
  // production
  apiKey: "AIzaSyBtsm8sjuS0HBH7DePONF4XkiTiYd4PaAU",
  authDomain: "mqtt-web-panel-star-holden.firebaseapp.com",
  projectId: "mqtt-web-panel-star-holden",
  storageBucket: "mqtt-web-panel-star-holden.appspot.com",
  messagingSenderId: "9305743936",
  appId: "1:9305743936:web:b43b0759be23fe8a3375a5"
});

export const db = getFirestore(app);
export const auth = getAuth();
