import { useEffect, useState } from "react";
import Button from "../Button";
import Input from "../Input";
import Label from "../Label";
import MediaPopup from "../popup/MediaPopup";
import Switch from "../Switch";
import FormRow from "./FormRow";
import Select from "./Select";

const WidgetInput = ({ input, onChange = () => {} }) => {
  const [inputs, setInputs] = useState(input);
  const [editing, setEditing] = useState(null);
  const [editForm, setEditForm] = useState([]);
  const [mediaPopup, setMediaPopup] = useState(false);

  function handleAddItem() {
    let value = {};
    let valid = true;
    inputs.inputs.map((input) => {
      value[input.name] = input.value;

      if (input.element == "switch" && !input.value)
        value[input.name] = false;

      /*
      if (input.element != "switch" && (input.value == "" || !input.value))
        valid = false;
        */
    });

    if (!valid)
      return;

    let values = inputs.value ? inputs.value : [];
    const newInputs = {...inputs, value: values};
    values.push(value);
    setInputs(newInputs);

    let temp = newInputs;
    temp.inputs.map((input) => {
      input.value = "";
    });
    setInputs(temp);
    onChange(values);
  }

  function handleRemoveItem(index) {
    let values = inputs.value;
    values.splice(index, 1);

    setInputs({...inputs, value: values});
    onChange(values);
  }

  function handleOnChangeItem(index, value) {
    let temp = inputs.inputs;
    temp[index].value = value;

    setInputs({...inputs, inputs: temp});
  }

  function handleOnEditToggle(index) {
    if (editing != index) {
      let temp = [];
      inputs.inputs.map((item, key) => {
        temp.push({...item, value: inputs.value[index][item.name]});
      });
      setEditForm(temp);
    } else {
      setEditForm([]);
    }

    setEditing(editing == index ? null : index);
  }

  function handleSubItemSave() {
    if (editing == null)
      return;

    let value = {};
    let valid = true;
    editForm.map((input) => {
      value[input.name] = input.value;
      /*
      if (input.element != "switch" && (input.value == "" || !input.value))
        valid = false;
        */
    });

    if (!valid)
      return;

    let values = inputs.value ? inputs.value : [];
    values[editing] = value;
    const newInputs = {...inputs, value: values};
    setInputs(newInputs);

    onChange(values);

    setEditing(null);
    setEditForm([]);
  }

  function handleSubItemInputChange(e, key) {
    let temp = editForm;
    temp[key].value = e;
    setEditForm(temp);
  }

  switch(input.element) {
    case 'media':
      return (
        <FormRow>
          <Label>{inputs.label}</Label>
          {inputs.value && inputs.value != null ?
            <div className="text-orange">{inputs.value}</div>
          : <></>}
          <Button onClick={() => setMediaPopup(true)}>Pick image</Button>
          {mediaPopup ?
            <MediaPopup onClose={() => setMediaPopup(false)} onSave={(path) => {onChange(path);setMediaPopup(false);}} />
          : <></>}
        </FormRow>
      );
    case 'select':
      return (
        <FormRow>
          <Label>{inputs.label}</Label>
          <Select defaultValue={input.value ? input.value : ""} options={input.options} onChange={(e) => onChange(e)} />
        </FormRow>
      );
    case 'icon':
      return (
        <FormRow>
          <Label>{inputs.label}</Label>
          <Select defaultValue={input.value ? input.value : ""} options={input.options} withIcon={true} onChange={(e) => onChange(e)} />
        </FormRow>
      );
    case 'switch':
      return (
        <FormRow>
          <Label>{inputs.label} ({inputs.value != null && inputs.value == true ? 
            <span className="font-bold text-green">Enabled</span>
            :
            <span className="font-bold text-red">Disabled</span>
          })</Label>
          <Switch defaultValue={inputs.value} onClick={(e) => {onChange(e); setInputs({...inputs, value: e})}} />
        </FormRow>
      );
    case 'list':
      return (
        <FormRow>
          <Label>{inputs.label}</Label>
          <div className="flex flex-col gap-2 p-2 border border-gray rounded-lg">
            <div className="flex flex-col gap-2 border border-gray p-4 rounded">
              {inputs.inputs.map((subInput, index) =>
                <WidgetInput input={subInput} key={index} onChange={(value) => handleOnChangeItem(index, value) } />
              )}
              <Button onClick={() => handleAddItem()}>Add</Button>
            </div>
            {inputs.value && inputs.value.map((item, itemIndex) =>
            <div className="border border-gray p-4 rounded" key={itemIndex}>
              <div className="flex items-center justify-between">
                <div className="flex gap-2">
                  <Button type="secondary" onClick={() => handleOnEditToggle(itemIndex)}>
                    <img className="w-[18px]" src="/icons/pen.svg" />
                  </Button>
                  <Button type="danger" onClick={() => handleRemoveItem(itemIndex)}>
                    <img src="/icons/thrash.svg" />
                  </Button>
                </div>

                <div className="text-lg text-white">{item.title}</div>
              </div>
              <div className="flex flex-col gap-2 mt-2">
              {editing == itemIndex ?
                <>
                  {editForm.map((formItem, key) =>
                    <div key={key}>
                      <WidgetInput input={formItem} onChange={(e) => handleSubItemInputChange(e, key)} />
                    </div>
                  )}
                  <div className="mt-4">
                    <Button onClick={() => handleSubItemSave()}>Save</Button>
                  </div>
                </>
              : <>
                {inputs.inputs.map((subInput, index) =>
                  <div key={index} className="flex justify-between text-white text-sm">
                    <div className="font-bold">{subInput.label}:</div>
                    <div>{item[subInput.name]}</div>
                  </div>
                )}
                </>}
              </div>
            </div>
            )}
          </div>
        </FormRow>
      );
    default:
      return (
        <FormRow>
          <Label>{inputs.label}</Label>
          <Input defaultValue={inputs.value} type={inputs.type} onChange={(e) => onChange(e.target.value)} />
        </FormRow>
      );
  }
}

export default WidgetInput;
