import { useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
/*
import { Heading, Button, Input, Stack, Box, FormControl, FormLabel, useToast } from '@chakra-ui/react';
import BlankLayout from '../components/BlankLayout';
*/
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate, Link } from 'react-router-dom';
import Auth from '../layouts/Auth';
import FormRow from '../components/form/FormRow';
import Label from '../components/Label';
import Input from '../components/Input';
import Button from '../components/Button';
import H1 from '../components/h1';

const PasswordResetPage = () => {
  const [email, setEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const {dispatch} = useContext(AuthContext);

  /*
  const toast = useToast();

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    if (!email) {
      return;
    }

    try {
      setIsLoading(true);
      sendPasswordResetEmail(auth, email)
        .then(() => {
          toast({
            description: "Password reset e-mail sent!",
            status: "success",
          });
        })
        .catch((error) => {
          toast({
            description: error.message,
            status: "error",
          });
        });
      setIsLoading(false);
      setEmail("");
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  }

  return (
    <BlankLayout>
      <Box maxW="400px" my="100px" mx="auto">
        <Heading as="h1" mb="20px">Password Reset</Heading>
        <form onSubmit={handlePasswordReset}>
          <Stack spacing={3}>
            <FormControl>
              <FormLabel>E-mail</FormLabel>
              <Input onChange={(event) => setEmail(event.target.value)} />
            </FormControl>
            <Button colorScheme="blue" isLoading={isLoading} type="submit">Reset Password</Button>
            <Link to="/login">Go back to login</Link>
            {errorMsg != "" ? errorMsg : ''}
          </Stack>
        </form>
      </Box>
    </BlankLayout>
  );
  */

  return (
    <Auth>
      <div className="mt-[20px] p-[20px] max-w-[600px] w-full bg-black-light border border-gray rounded-lg">
        <H1>Password Reset</H1>
        <form className="mt-[20px] flex flex-col gap-4">
          <FormRow>
            <Label>E-mail</Label>
            <Input type="email" />
          </FormRow>
          <div className="flex items-center justify-between">
            <Button>Send reset password mail</Button> 
            <Link className="text-white hover:text-orange" to="/login">Go back to login</Link>
          </div>
        </form>
      </div>
    </Auth>
  );
}

export default PasswordResetPage;
