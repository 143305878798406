import { useEffect, useState } from "react";

const Button = ({ children, icon = "", rounded = true, type = 'primary', onClick = () => {}, className, loading = false, disabled = false  }) => {
  const commonClasses = "transition-all duration-250 flex items-center gap-2 justify-center py-[6px] px-[8px] border-4 font-bold text-black" + (rounded ? " rounded-lg" : "");
  const primaryClasses = " border-orange-dark bg-orange hover:bg-orange-light hover:border-orange-light";
  const secondaryClasses = " border-gray-dark bg-gray hover:bg-gray-light hover:border-gray-light";
  const dangerClasses = " border-red-dark bg-red hover:bg-red-light hover:border-red-light";
  const greenClasses = " border-green-dark bg-green hover:bg-green-light hover:border-green-light";
  const blueClasses = " border-blue-dark bg-blue hover:bg-blue-light hover:border-blue-light";

  function getTypeClasses() {
    if (type == 'primary') {
      return primaryClasses;
    } else if (type == 'secondary') {
      return secondaryClasses;
    } else if (type == 'danger') {
      return dangerClasses;
    } else if (type == 'green') {
      return greenClasses;
    } else if (type == 'blue') {
      return blueClasses;
    }
  }

  return (
    <button className={commonClasses + getTypeClasses() + " " + (disabled ? "opacity-40" : "") + " " + className} onClick={onClick} disabled={loading || disabled}>
      {!loading ? (
        <>
          {icon !== "" ?
            <img src={icon} />
          : <></>}
          {children}
        </>
      )
      : <span>LOADING</span>}
    </button>
  );
}

export default Button;
