import React from 'react';
import { ToastContainer } from 'react-toastify';

const Auth = ({children, showLogo = true}) => {
  return (
    <div className="min-h-screen bg-black">
      
      <ToastContainer position='top-center' theme='colored' />

      <div className="min-h-screen flex flex-col justify-center items-center">
        {showLogo ? 
        <img src="/logo.png" className="w-[150px] h-[150px]" />
        : <></>}
        {children}
      </div>

    </div>
  );
}

export default Auth;
