import { useState, useEffect, useContext } from 'react';
/*
import {
  Heading,
  Flex,
  Button,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Skeleton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from '@chakra-ui/react';
import { ChevronDownIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
*/
import { Link } from 'react-router-dom';
// import DashboardLayout from '../components/DashboardLayout';
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import projectService from '../services/project';
import { AuthContext } from '../context/AuthContext';
import { ProjectContext } from '../context/ProjectContext';

// v2
import Dashboard from '../layouts/Dashboard';
import H1 from '../components/h1';
import Button from '../components/Button';
import DeleteProjectPopup from '../components/popup/DeleteProjectPopup';
import { toast } from 'react-toastify';

const ProjectsPage = () => {
  const [deletePopup, setDeletePopup] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projects, setProjects] = useState([]);
  const {currentUser} = useContext(AuthContext);
  const currentProject = useContext(ProjectContext);

  const fetchData = async () => {
    let res = null;
    if (currentUser.role === "admin") {
      res = await projectService.getAll();
    } else {
      res = await projectService.getMyProjects(currentUser);
    }
    if (res) {
      setProjects(res);
    }
  };

  useEffect(() => {
    currentProject.dispatch({type: "RESET"});
    fetchData();
  }, []);

  return (
    <Dashboard>
      <div className="p-[20px] max-w-[600px] w-full">
        <H1>Projects</H1>

        {currentUser.role == "admin" ?
        <Link to="/projects/create" className="block w-fit mt-6">
          <Button rounded={false}>New project</Button>
        </Link>
        : <></>}

        <table className="mt-[30px] w-full">
          <thead>
            <tr>
              <th><div className="text-left text-lg text-bold text-white">Name</div></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project, index) =>
            <tr key={index}>
              <td className="py-3">
                <Link to={`projects/${project.id}`}>
                  <div className="text-orange">{project.name}</div>
                </Link>
              </td>
              <td className="py-3">
                {currentUser.role == "admin" ?
                <div className="flex gap-2">
                  <Link to={`/projects/${project.id}/edit`}>
                    <Button rounded={false} type="secondary"><img className="w-[20px] h-[20px]" src="/icons/pen.svg" /></Button>
                  </Link>
                  <Button rounded={false} onClick={() => {setSelectedProject(project); setDeletePopup(true)}} type="danger"><img src="/icons/thrash.svg" /></Button>
                </div>
                : <></>}
              </td>
            </tr>
            )}
          </tbody>
        </table>
        {projects.length == 0 ?
          <div className="text-2xl text-white">There are no projects available...</div>
        : <></>}
        {deletePopup && selectedProject != null ?
          <DeleteProjectPopup project={selectedProject} onCancel={() => setDeletePopup(false)} onDelete={() => {setDeletePopup(false); fetchData();}} />
        : <></>}
      </div>
      {/*
      <Flex gap="20px" alignItems="center">
        <Heading as="h1">Projects</Heading>
        {currentUser.role === "admin" ?
        <Button as={Link} to="/projects/create" size="sm">Create new project</Button> : <></>
        }
      </Flex>

      <Skeleton isLoaded={!isLoading}>
        <TableContainer mt="20px">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {projects.map((project) =>
                <Tr key={project.id}>
                  <Td color="blue"><Link to={`projects/${project.id}`}>
                    {project.name}
                  </Link></Td>
                  <Td textAlign="right">
                    {currentUser.role === "admin" ?
                    <Menu>
                      <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>Actions</MenuButton>
                      <MenuList>
                        <MenuItem as={Link} icon={<EditIcon />} to={`/projects/${project.id}/edit`}>Edit</MenuItem>
                        <MenuItem icon={<DeleteIcon />} onClick={(event) => handleDelete(event, project)}>Delete</MenuItem>
                      </MenuList>
                    </Menu> : <></>
                    }
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Skeleton>

      */}
    </Dashboard>
  );
}

export default ProjectsPage;
