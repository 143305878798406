import BasePopup from "./BasePopup";
import Button from '../Button';
import projectService from "../../services/project";
import { toast } from "react-toastify";

const DeleteProjectPopup = ({ project, onDelete = () => {}, onCancel = () => {} }) => {
  function handleDelete() {
    projectService.deleteProject(project);
    toast.success('Project deleted');
    onDelete();
  }

  return (
    <BasePopup>
      <div>
        <div className="text-[20px] text-white font-bold">Delete Project</div>
      </div>

      <p className="text-white py-3">Are you sure about deleting {project.name} project ? This action is not reversable!</p>
      
      <div className="flex gap-2">
        <Button type="danger" onClick={handleDelete}>Delete</Button>
        <Button type="secondary" onClick={onCancel}>Cancel</Button>
      </div>
    </BasePopup>
  );
}

export default DeleteProjectPopup;
