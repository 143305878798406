import React from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const Dashboard = ({children, sidebarInside}) => {
  return (
    <div className="flex h-screen bg-black">
      <ToastContainer position='bottom-center' theme='colored' />

      <Sidebar inside={sidebarInside} />

      <div className="grow overflow-y-auto">
        {children}
      </div>

    </div>
  );
}

export default Dashboard;
