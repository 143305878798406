import mqtt from 'mqtt/dist/mqtt';

function clearTopics() {
  localStorage.setItem('topics', JSON.stringify([]));
}

function initTopics() {
  clearTopics();
}

function getTopics() {
  return JSON.parse(localStorage.getItem('topics'));
}

function addTopic(topic) {
  let temp = getTopics();

  if (temp == null)
    initTopics();

  if (temp.includes(topic))
    return;

  temp.push(topic);
  localStorage.setItem('topics', JSON.stringify(temp));
}

function removeTopic(topic) {
  let temp = getTopics();

  if (!temp.includes(topic))
    return;

  temp.splice(temp.indexOf(topic), 1);
  localStorage.setItem('topics', JSON.stringify(temp));
}

function getClient(websocketUrl) {
  initTopics();
  const client = mqtt.connect(websocketUrl, {
    rejectUnauthorized: true,
    keepalive: 60 * 30,
  });
  client.stream.on("error", (err) => {
    //errorHandler(`Connection to ${websocketUrl} failed`);
    console.error(err);
    client.end();
  });
  return client;
}

function subscribe(client, topic) {
  const callBack = (err, granted) => {
    if (err) {
      console.error(err);
    }
  };
  addTopic(topic);
  console.log(`MQTT: Subscribed to ${topic} topic.`);
  return client.subscribe(topic, callBack);
}

function onMessage(client, callBack) {
  client.on("message", (topic, message, packet) => {
    callBack(topic, message);
  });
}

function listenTopic(client, listenTopic, callBack) {
  onMessage(client, (topic, message) => {
    if (listenTopic && topic === listenTopic) {
      let newVal = message.toString();
      callBack(newVal);
    }
  });
}

function publish(client, topic, payload) {
  if (client) {
    client.publish(topic, payload, (error) => {
      if (error) {
        console.error(error);
        return;
      }
    });

    console.log(`MQTT: Message ${payload} published on ${topic} topic.`);
  }
}

function unsubscribe(client, topic) {
  removeTopic(topic);
  client.unsubscribe(topic);
  console.log(`MQTT: Unsubscribed from ${topic} topic.`);
}

function closeConnection(client) {
  clearTopics();
  client.end();
  console.log("MQTT: Connection closed.");
}

const mqttService = {
  getClient,
  subscribe,
  onMessage,
  unsubscribe,
  closeConnection,
  publish,
  listenTopic
};

export default mqttService;
