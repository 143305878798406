import WidgetBase from "./WidgetBase";
import WidgetText from "./WidgetText";
import WidgetLed from "./WidgetLed";
import WidgetNumber from "./WidgetNumber";
import WidgetBar from "./WidgetBar";
import WidgetToggle from "./WidgetToggle";
import WidgetLineChart from "./WidgetLineChart";
import WidgetGauge from "./WidgetGauge";
import WidgetButton from "./WidgetButton";
import WidgetMultiLineChart from "./WidgetMultiLineChart";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import WidgetInputs from "./WidgetInputs";

const Widget = ({ widget, sectionId }) => {
  const {currentUser} = useContext(AuthContext);

  if (!widget.visible && currentUser.role != "admin")
    return <></>;

  switch (widget.type) {
    case "text": {
      return <WidgetText widget={widget} key={widget.uuid} sectionId={sectionId} />;
    }
    case "number": {
      return <WidgetNumber widget={widget} key={widget.uuid} sectionId={sectionId} />;
    }
    case "bar-chart": {
      return <WidgetBar widget={widget} key={widget.uuid} sectionId={sectionId} />;
    }
    case "line-chart": {
      return <WidgetLineChart widget={widget} key={widget.uuid} sectionId={sectionId} />;
    }
    case "multi-line-chart": {
      return <WidgetMultiLineChart widget={widget} key={widget.uuid} sectionId={sectionId} />;
    }
    case "toggle": {
      return <WidgetToggle widget={widget} key={widget.uuid} sectionId={sectionId} />;
    }
    case "indicator": {
      return <WidgetLed widget={widget} key={widget.uuid} sectionId={sectionId} />;
    }
    case "gauge": {
      return <WidgetGauge widget={widget} key={widget.uuid} sectionId={sectionId} />;
    }
    case "button": {
      return <WidgetButton widget={widget} key={widget.uuid} sectionId={sectionId} />;
    }
    case "input": {
      return <WidgetInputs widget={widget} key={widget.uuid} sectionId={sectionId} />;
    }
    default:
      return <WidgetBase widget={widget} sectionId={sectionId}></WidgetBase>;
  }
}

export default Widget;
