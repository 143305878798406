import { useContext, useEffect, useState } from "react";
import H2 from "./h2";
import Widget from "./widgets/Widget";
import EditSectionPopup from "./popup/EditSectionPopup";
import WidgetNew from "./widgets/WidgetNew";
import { ProjectStateContext } from "../context/ProjectStateContext";
import Button from './Button';
import DeleteSectionPopup from "./popup/DeleteSectionPopup";

const ProjectSection = ({ section }) => {
  const [editSectionPopup, setEditSectionPopup] = useState(false);
  const [deleteSectionPopup, setDeleteSectionPopup] = useState(false);
  const {projectState} = useContext(ProjectStateContext);

  return (
    <section id={section.uuid} className="p-[20px]">
      <div className="flex gap-2 pb-4">
        {projectState == 'edit' ?
          <>
            <Button type="secondary" onClick={() => setEditSectionPopup(true)}>
              <img src="/icons/pen.svg" className="w-[18px]" />
            </Button>
            <Button type="danger" onClick={() => setDeleteSectionPopup(true)} className="mr-3">
              <img src="/icons/thrash.svg" />
            </Button>
          </>
        : <></>}
        <H2>{section.title}</H2>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-[10px]">
      {section.children.map((item, index) => 
        <Widget widget={item} key={index} sectionId={section.uuid} />
      )}
      {projectState == 'edit' ?
        <WidgetNew key="new-widget-widget" sectionId={section.uuid} />
      : <></>}
      </div>
      
      {editSectionPopup ?
        <EditSectionPopup key="edit-section-popup" section={section} onSave={() => setEditSectionPopup(false)} onClose={() => setEditSectionPopup(false)} />
      : <></>}
      
      {deleteSectionPopup ?
        <DeleteSectionPopup key="delete-section-popup" section={section} onSave={() => setDeleteSectionPopup(false)}
        onCancel={() => setDeleteSectionPopup(false)} />
      : <></>}
    </section>
  );
}

export default ProjectSection;
