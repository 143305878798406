import BasePopup from "./BasePopup";
import Button from '../Button';
import FormRow from '../form/FormRow';
import Label from '../Label';
import Input from '../Input';
import { useState, useContext } from "react";
import { ProjectContext } from '../../context/ProjectContext';
import projectService from '../../services/project';
import { v4 as uuidv4 } from 'uuid';
import logService from '../../services/log';
import { AuthContext } from "../../context/AuthContext";

const NewSectionPopup = ({ onSave = () => {}, onClose = () => {}}) => {
  const [title, setTitle] = useState("");
  const {project, dispatch} = useContext(ProjectContext);
  const {currentUser} = useContext(AuthContext);

  function handleSave() {
    if (title == "") {
      return;
    }

    let temp = project;
    temp.layout.push({
      title: title,
      children: [],
      uuid: uuidv4(),
    });

    dispatch({type: "SET", payload: temp});

    projectService.saveProject(temp);

    logService.log(`section ${title} created by ${currentUser.name}`, project.id, currentUser.uid);

    onSave();
  }

  return (
    <BasePopup>
      <div>
        <div className="text-[20px] text-white font-bold">New Section</div>
      </div>

      <form className="my-6">
        <FormRow>
          <Label>Title</Label>
          <Input onChange={(e) => setTitle(e.target.value)} />
        </FormRow>
      </form>
      
      <div className="flex gap-2">
        <Button type="primary" onClick={handleSave}>Create</Button>
        <Button type="secondary" onClick={onClose}>Close</Button>
      </div>
    </BasePopup>
  );
}

export default NewSectionPopup;
