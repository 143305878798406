import { useEffect, useState } from "react";

const Select = ({ defaultValue, options = [], withIcon = false, onChange = () => {}}) => {
  const [value, setValue] = useState(defaultValue);
  const classes = "p-[8px] text-[15px] text-white bg-black-light border border-gray rounded-lg outline-none ";

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  function handleChange(e) {
    setValue(e.target.value);
    onChange(e.target.value);
  }

  return (
    <select className={classes} value={value} onChange={handleChange}>
      <option value="" selected disabled>Select</option>
      {options.map((option, index) =>
        <option key={index} value={option.id}>{option.value}</option>
      )}
    </select>
  );
}

export default Select;
