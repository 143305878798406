import { Component, createRef, useEffect, useRef, useState } from "react";
import debounce from "../../utils/debounce";

/*
const LineChart = ({values, unit, color = '#F38A4F'}) => {
  const ref = useRef(null);
  const [dimensions, setDimensions] = useState({
    width: 0, 
  });
  const [points, setPoints] = useState([]);
  const [upLow, setUpLow] = useState({
    upper: null,
    lower: null
  });
  const height = 100;
  const [pointsStr, setPointsStr] = useState("");

  function pointsToString(points) {
    let response = "";
    points.map((point, index) => {
      if (index != 0)
        response += " ";
      response += point.x.toString() + "," + point.y.toString();
    });

    setPointsStr(response);

    return response;
  }

  function definePoints(values) {
    if (values != null && values.length) {
      let temp = [];
      let lowest = null;
      let highest = null;
      let length = ref.current.clientWidth;
      const widthLevel = length / (values.length - 1);

      values.map((value, index) => {
        if (index == 0) {
          lowest = value;
          highest = value;
          return;
        }

        if (value < lowest) {
          lowest = value;
        } else if (value > highest) {
          highest = value;
        }
      });

      setUpLow({
        upper: highest,
        lower: lowest
      });
      
      const level = height / (highest - lowest);

      values.map((value, index) => {
        temp.push({
          x: index * widthLevel,
          y: ((highest - value) * level) + 20,
        });
      });

      setPoints(temp);

      pointsToString(temp);
    }
  }

  useEffect(() => {
    console.log('inital');
    setDimensions({
      width: ref.current.clientWidth,
    });

    definePoints(values);

    const debouncedHandleResize = debounce(function handleResize() {
      console.log('debounce');
      setDimensions({
        width: ref.current.clientWidth,
      });
      definePoints(values);
    }, 1000);

    const test = () => {
      console.log(values);
    }

    window.addEventListener('resize', debouncedHandleResize);

    setInterval(test, 1000);

    return _ => {
      window.removeEventListener('resize', debouncedHandleResize);
      clearInterval(test);
    }
  }, []);

  return (
    <div>
      <svg className="w-full h-[150px]" ref={ref}>
        <text x={0} y={20} fill="#ffffff">{upLow.upper}</text>
        <text x={dimensions.width - 60} y={20} fill={color}>{values != null ? values[values.length - 1] : 0}</text>
        <polyline
          fill="none"
          stroke={color}
          strokeWidth={3}
          points={pointsStr}
        />
        <text x={0} y={140} fill="#ffffff">{upLow.lower}</text>
      </svg>
    </div>
  );
}
*/

class LineChart extends Component {
  state = {
    upper: null,
    lower: null,
    width: null,
    points: [],
    pointsStr: "",
    isRendering: false,
  };

  height = 100;

  ref = createRef();

  debouncedHandleResize = debounce(() => {
    this.setState({width: this.ref.current.clientWidth});
    this.definePoints(this.props.values);
  }, 1000);

  componentDidMount() {
    this.setState({width: this.ref && this.ref.current ? this.ref.current.clientWidth : 100});
    this.definePoints(this.props.values);

    window.addEventListener('resize', this.debouncedHandleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedHandleResize);
  }

  pointsToString(points) {
    if (points.length < 2)
      return;
    let response = "";
    points.map((point, index) => {
      if (index != 0)
        response += " ";
      response += point.x.toString() + "," + point.y.toString();
    });

    this.setState({pointsStr: response});
  }

  definePoints(values) {
    if (values != null && values.length < 2)
      return;

    if (values != null && values.length) {
      let temp = [];
      let lowest = null;
      let highest = null;
      let length = this.ref && this.ref.current ? this.ref.current.clientWidth : 200;
      const widthLevel = length / (values.length - 1);

      values.map((value, index) => {
        if (index == 0) {
          lowest = value;
          highest = value;
          return;
        }

        if (value < lowest) {
          lowest = value;
        } else if (value > highest) {
          highest = value;
        }
      });

      this.setState({lower: lowest, upper: highest});
      
      let level = 1;
      if (highest != lowest) {
        level = this.height / (highest - lowest);
      }

      values.map((value, index) => {
        temp.push({
          x: index * widthLevel,
          y: ((highest - value) * level) + 20,
        });
      });

      this.setState({points: temp});

      this.pointsToString(temp);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps != this.props && !this.state.isRendering) {
      setTimeout(() => {
        this.setState({isRendering: true});
        this.definePoints(this.props.values);
        this.setState({isRendering: false});
      }, 1000);
    }
  }

  render() {
    return (
      <div>
        <svg className="w-full h-[150px]" ref={this.ref}>
          <text className="font-future" x={0} y={20} fill="#ffffff">{this.state.upper}</text>
          <text className="font-future" x={this.state.width - 60} y={20} fill={this.props.color}>{this.props.values != null ? this.props.values[this.props.values.length - 1] : 0}</text>
          <polyline
            fill="none"
            stroke={this.props.color}
            strokeWidth={3}
            points={this.state.pointsStr}
          />
          <text className="font-future" x={0} y={140} fill="#ffffff">{this.state.lower}</text>
        </svg>
      </div>
    );
  }
}

export default LineChart;
