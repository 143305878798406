const ProjectReducer = (state, action) => {
  switch (action.type) {
    case "SET": {
      return {
        project: action.payload,
      };
    }
    case "RENDER": {
      return {...state};
    }
    case "RESET": {
      return {
        project: {
          id: "",
          adminMessage: "",
          autoConnect: false,
          broker: null,
          brokerFullUrl: "",
          layout: null,
          name: "",
        },
      };
    }
    case "RESET_LAYOUT_VALUES": {
      let temp = state.project;
      temp.layout.map((section) => {
        section.children.map((widget) => {
          widget.value = "";
        });
      });
      return {
        project: temp,
      };
    }
    default:
      return state;
  }
}

export default ProjectReducer;
