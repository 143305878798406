import { useEffect, useState } from "react";

const Input = ({
  type = 'text',
  onChange = () => {},
  defaultValue = '',
  required = false,
  className = ""
}) => {
  const [value, setValue] = useState(defaultValue);
  const classes = "p-[8px] text-[15px] text-white bg-black-light border border-gray rounded-lg outline-none ";

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  if (type == 'color') {
    return (
      <div className="p-[8px] text-[15px] text-white bg-black-light border border-gray rounded-lg outline-none">
        <input type={type} value={value} className="w-full bg-transparent outline-none border-none" onChange={(e) => { setValue(e.target.value); onChange(e); }} required={required} />
      </div>
    );
  }

  return (
    <input type={type} value={value} className={classes + className} onChange={(e) => { setValue(e.target.value); onChange(e); }} required={required} />
  );
}

export default Input;
