import { useState } from 'react';
/*
import {
  Heading,
  Input,
  Button,
  Stack,
  FormControl,
  FormLabel,
  FormHelperText,
  Textarea,
  Switch,
  useToast,
} from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
*/
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { setDoc, doc, serverTimestamp } from 'firebase/firestore';
import slugify from 'react-slugify';
import Dashboard from '../layouts/Dashboard';
import FormRow from '../components/form/FormRow';
import Label from '../components/Label';
import Input from '../components/Input';
import H1 from '../components/h1';
import Button from '../components/Button';
import Switch from '../components/Switch';
import { toast } from 'react-toastify';

const ProjectsCreatePage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    name: "",
    protocol: "wss",
    address: "broker.emqx.io",
    port: "8084",
    path: "/mqtt",
    identifier: "",
    adminMessage: "",
    autoConnect: false,
  });

  async function handleSave(e) {
    e.preventDefault();

    let project = {
      name: data.name,
      broker: {
        protocol: data.protocol,
        address: data.address,
        port: data.port,
        path: data.path,
      },
      brokerFullUrl: `${data.protocol}://${data.address}:${data.port}${data.path}`,
      identifier: data.identifier,
      adminMessage: data.adminMessage,
      autoConnect: data.autoConnect,
      layout: [],
      timeStamp: serverTimestamp()
    };

    try {
      await setDoc(doc(db, "projects", slugify(data.name)), project);
      toast.success('Project created');
      navigate("/");
    } catch (err) {
      toast.error(err);
      console.error(err);
    }
  }

  return (
    <Dashboard>
      <div className="p-[20px] max-w-[600px] w-full">
        <H1>New Project</H1>

        <form className="my-8 flex flex-col gap-4">

          <FormRow>
            <Label>Name</Label>
            <Input type="text" defaultValue={data.name} onChange={(e) => setData({...data, name: e.target.value})} />
          </FormRow>

          <FormRow>
            <Label>Broker Protocol</Label>
            <Input type="text" defaultValue={data.protocol} onChange={(e) => setData({...data, protocol: e.target.value})} />
          </FormRow>

          <FormRow>
            <Label>Broker Address</Label>
            <Input type="text" defaultValue={data.address} onChange={(e) => setData({...data, address: e.target.value})} />
          </FormRow>

          <FormRow>
            <Label>Broker Port</Label>
            <Input type="text" defaultValue={data.port} onChange={(e) => setData({...data, port: e.target.value})} />
          </FormRow>

          <FormRow>
            <Label>Broker Path</Label>
            <Input type="text" defaultValue={data.path} onChange={(e) => setData({...data, path: e.target.value})} />
          </FormRow>

          <FormRow>
            <Label>Unique Identifier</Label>
            <Input type="text" defaultValue={data.identifier} onChange={(e) => setData({...data, identifier: e.target.value})} />
          </FormRow>

          <FormRow>
            <Label>Admin Message</Label>
            <Input type="text" defaultValue={data.adminMessage} onChange={(e) => setData({...data, adminMessage: e.target.value})} />
          </FormRow>

          <FormRow>
            <Label>Auto Connect ({data.autoConnect ? "Enabled" : "Disabled"})</Label>
            <Switch defaultValue={data.autoConnect} onClick={(e) => setData({...data, autoConnect: e})} />
          </FormRow>

          <Button onClick={handleSave}>Save</Button>

        </form>
      </div>
    </Dashboard>
  );

  /*
  const toast = useToast();

  async function handleCreate(e) {
    e.preventDefault();

    if (!data.name) {
      toast({
        description: "Please fill the empty fields",
        status: "warning",
      });
      return;
    }

    setIsLoading(true);
    try {
      await setDoc(doc(db, "projects", slugify(data.name)), {
        name: data.name,
        broker: {
          protocol: data.protocol,
          address: data.address,
          port: data.port,
          path: data.path
        },
        identifier: data.identifier,
        brokerFullUrl: `${data.protocol}://${data.address}:${data.port}${data.path}`,
        layout: [],
        adminMessage: data.adminMessage,
        autoConnect: data.autoConnect,
        timeStamp: serverTimestamp()
      });
      setIsLoading(false);
      navigate("/");
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      toast({
        description: err.message,
        status: "error",
      });
    }
  }

  return (
    <DashboardLayout>
      <Heading as="h1">New Project</Heading>
      <form onSubmit={handleCreate}>
        <Stack maxW="400px" mt="20px">

          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input onChange={(e) => setData({...data, name: e.target.value})} required />
          </FormControl>

          <FormControl>
            <FormLabel>Broker Protocol</FormLabel>
            <Input value={data.protocol} onChange={(e) => setData({...data, protocol: e.target.value})} required />
          </FormControl>

          <FormControl>
            <FormLabel>Broker Address</FormLabel>
            <Input value={data.address} onChange={(e) => setData({...data, address: e.target.value})} required />
          </FormControl>

          <FormControl>
            <FormLabel>Broker Port</FormLabel>
            <Input value={data.port} onChange={(e) => setData({...data, port: e.target.value})} required />
          </FormControl>

          <FormControl>
            <FormLabel>Broker Path</FormLabel>
            <Input value={data.path} onChange={(e) => setData({...data, path: e.target.value})} />
          </FormControl>

          <FormControl>
            <FormLabel>Unique Identifier</FormLabel>
            <Input value={data.identifier} onChange={(e) => setData({...data, identifier: e.target.value})} />
            <FormHelperText>If you want to use this feature, please add / as well at the end of the identifier</FormHelperText>
          </FormControl>

          <FormControl>
            <FormLabel>Admin Message</FormLabel>
            <Textarea value={data.adminMessage} onChange={(e) => setData({...data, adminMessage: e.target.value})} />
          </FormControl>

          <FormControl>
            <FormLabel>Auto Connect</FormLabel>
            <Switch defaultChecked={data.autoConnect} onChange={(e) => setData({...data, autoConnect: e.target.checked})} />
            <FormHelperText mb="20px">When this feature is activated, your current project will be auto connected everytime you visit it.</FormHelperText>
          </FormControl>

          <Button colorScheme="blue" type="submit" isLoading={isLoading}>Create</Button>
        </Stack>
      </form>
    </DashboardLayout>
  );
  */
}

export default ProjectsCreatePage;
