import { useEffect, useContext, useState } from 'react';
import WidgetBase from './WidgetBase';
import Button from '../Button';
import mqttService from '../../services/mqtt';
import { ConnectionContext } from '../../context/ConnectionContext';
import { ProjectContext } from '../../context/ProjectContext';

const WidgetToggle = ({widget, sectionId}) => {
  const { connection } = useContext(ConnectionContext);
  const { project, dispatch } = useContext(ProjectContext);
  const [states, setStates] = useState([]);
  const gridClasses = "grid mt-2 gap-2 " + (widget.fields[0].value.length > 1 ? "grid-cols-2" : "grid-cols-1");

  useEffect(() => {
    const temp = [];
    widget.fields[1].value.map((value) => {
      temp.push({
        loading: false,
        value: value.offValue
      });
    });
    setStates(temp);
  }, []);

  useEffect(() => {
    if (connection && connection.connected) {
      widget.fields[1].value.map((toggle, index) => {
        mqttService.subscribe(connection.client, project.identifier + toggle.topic);
        mqttService.listenTopic(connection.client, project.identifier + toggle.topic, (message) => {
          let temp = states;
          states[index].value = message;
          states[index].loading = false;
          setStates(temp);
        });
      });
    }
  }, [connection]);

  function handleToggle(toggle, index, newState) {

    let temp = states;
    temp[index].loading = true;
    setStates(temp);

    dispatch({type: 'SET', payload: project});

    if (!toggle || !newState || !connection || !connection.connected)
      return;

    mqttService.subscribe(connection.client, project.identifier + toggle.topic);
    mqttService.publish(connection.client, project.identifier + toggle.topic, newState);
    mqttService.unsubscribe(connection.client, project.identifier + toggle.topic);
  }

  function toggleRow(toggle, index) {
    if (!states.length)
      return <></>;

    if (states[index].value == toggle.onValue)
      return (
        <div>
          <div className="text-white mb-1">{toggle.label}</div>
          <Button rounded={false} loading={states[index].loading} type="green" className="w-full" onClick={() => handleToggle(toggle, index, toggle.offValue)}>ON</Button>
        </div>
      );
    else
      return (
        <div>
          <div className="text-white mb-1">{toggle.label}</div>
          <Button rounded={false} loading={states[index].loading} type="danger" className="w-full" onClick={() => handleToggle(toggle, index, toggle.onValue)}>OFF</Button>
        </div>
      );
  }

  return (
    <WidgetBase widget={widget} sectionId={sectionId}>
      <div className={gridClasses}>
      {widget.fields[1].value.map((toggle, index) =>
        <div key={index}>
        {toggleRow(toggle, index)}
        </div>
      )}
      </div>
    </WidgetBase>
  );
}

export default WidgetToggle;
