import { useState, useEffect, useContext } from 'react';
/*
import {
  Heading,
  List,
  ListItem,
  Button,
  Flex,
} from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
*/
import { useParams, Link } from 'react-router-dom';
import logService from '../services/log';
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import Dashboard from '../layouts/Dashboard';
import { ProjectContext } from '../context/ProjectContext';
import Button from '../components/Button';
import ProjectHeader from '../components/ProjectHeader';
import H1 from '../components/h1';
import ProjectSidebar from '../components/layout/ProjectSidebar';

const LogsPage = () => {
  const {project, dispatch} = useContext(ProjectContext);
  const [data, setData] = useState({
    logs: [],
  });

  let { id } = useParams();

  const fetchData = async () => {
    const res = await logService.retreiveLogs(id);
    if (res) {
      setData({...data, logs: res});
    }
  }

  const handleClear = async () => {
    if (!data.logs.length) {
      return;
    }

    await deleteDoc(doc(db, "logs", "general", id, data.logs[0].id));
    fetchData();
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Dashboard sidebarInside={<ProjectSidebar />}>

      <div className="p-[20px]">
        <H1>Logs</H1>

        <div className="flex items-center gap-4 mt-8">
          <Button onClick={() => handleClear()}>Clear logs</Button>
          <Link to={`/projects/${id}`}>
            <Button type="secondary">Go back</Button>
          </Link>
        </div>

        <div className="flex flex-col gap-1 mt-4">
        {data.logs.reverse().map((log, index) => 
          <div className="text-white" key={index}><b className="text-orange">{log.id}:</b> {log.messages}</div>
        )}
        </div>
      </div>

    </Dashboard>
  );
  /*

  return (
    <DashboardLayout>
      <Heading as="h1" mb="10px">Logs</Heading>
      <Flex alignItems="center">
        <Link to={`/projects/${id}`}>Go back</Link>
        <Button onClick={handleClear} ml="20px" colorScheme="red">Clear logs</Button>
      </Flex>
      <List mt="20px">
        {data.logs.reverse().map((log, index) => 
          <ListItem key={index}>{log.id}: {log.messages}</ListItem>
        )}
      </List>
    </DashboardLayout>
  );
  */
}

export default LogsPage;
