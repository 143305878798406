import { useContext } from 'react';
import mqttService from '../../services/mqtt';
import { ConnectionContext } from '../../context/ConnectionContext';
import { ProjectContext } from '../../context/ProjectContext';
import WidgetBase from './WidgetBase';
import Button from '../Button';

const WidgetButton = ({widget, sectionId}) => {
  const { connection } = useContext(ConnectionContext);
  const { project } = useContext(ProjectContext);
  const gridClasses = "grid mt-2 gap-2 " + (widget.fields[0].value.length > 1 ? "grid-cols-2" : "grid-cols-1");

  function buttonType(color) {
    switch(color) {
      case 'orange':
        return "primary";
      case 'red':
        return "danger";
      case 'gray':
        return "secondary";
      case 'green':
        return "green";
      case 'blue':
        return "blue";
    }
  }

  function handleClick(button) {
    if (!button || !connection || !connection.connected)
      return;

    mqttService.subscribe(connection.client, project.identifier + button.topic);
    mqttService.publish(connection.client, project.identifier + button.topic, button.message);
    mqttService.unsubscribe(connection.client, project.identifier + button.topic);
  }

  return (
    <WidgetBase widget={widget} sectionId={sectionId}>
      <div className={gridClasses}>
      {widget.fields[1].value.map((button, index) =>
        <Button rounded={false} key={index} icon={button.icon ? `/icons/${button.icon}.svg` : ""} onClick={(e) => handleClick(button)} type={buttonType(button.color)}>{button.label}</Button>
      )}
      </div>
    </WidgetBase>
  );
}

export default WidgetButton;
