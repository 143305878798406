import { useEffect, useContext, useState } from 'react';
import WidgetBase from './WidgetBase';
import mqttService from '../../services/mqtt';
import { ConnectionContext } from '../../context/ConnectionContext';
import { ProjectContext } from '../../context/ProjectContext';
import Input from '../Input';
import Button from '../Button';
import { toast } from 'react-toastify';
import { AuthContext } from '../../context/AuthContext';

const WidgetInputs = ({widget, sectionId}) => {
  const {currentUser} = useContext(AuthContext);
  const { connection } = useContext(ConnectionContext);
  const { project } = useContext(ProjectContext);
  const items = widget && widget.fields && widget.fields.length ? widget.fields[1].value : [];
  const [values, setValues] = useState([]);

  function fillValues() {
    let temp = [];
    items.map(() => temp.push({set: "", get: ""}));
    setValues(temp);
  }

  useEffect(() => {
    if (connection && connection.connected) {
      fillValues();
    }
  }, [connection]);

  function handleGet(item) {
    if (!connection || !connection.connected) {
      toast.error('Unable to get, not connected');
      return;
    }

    mqttService.subscribe(connection.client, project.identifier + item.getterTopic);
    mqttService.publish(connection.client, project.identifier + item.getterTopic, "ON");
    mqttService.unsubscribe(connection.client, project.identifier + item.getterTopic);

    toast.success(`Sent to topic "${item.getterTopic}"`);
  }

  function handleSet(item, index) {
    if (!connection || !connection.connected) {
      toast.error('Unable to set, not connected');
      return;
    }

    mqttService.subscribe(connection.client, project.identifier + item.setterTopic);
    mqttService.publish(connection.client, project.identifier + item.setterTopic, values[index].set);
    mqttService.unsubscribe(connection.client, project.identifier + item.setterTopic);

    toast.success(`Sent to topic "${item.setterTopic}"`);
  }

  function handleInputChange(index, value, type) {
    let temp = values;
    if (type == 'set')
      temp[index].set = value;
    if (type == 'get')
      temp[index].get = value;
    setValues(temp);
  }

  return (
    <WidgetBase widget={widget} sectionId={sectionId}>
      <div className="flex flex-col gap-2 mt-2">
        {items.map((item, key) =>
          <div key={key} className="flex flex-col gap-2">
            {!item.setterAdminOnly || currentUser.role == "admin" ?
            <div className="flex gap-2">
              <Input className="w-full" defaultValue={values && values.length ? values[key].set : ""} onChange={(e) => handleInputChange(key, e.target.value, 'set')} />
              <Button type="green" rounded={false} onClick={() => handleSet(item, key)}>Set</Button>
            </div>
            : <></>}
            {!item.getterAdminOnly || currentUser.role == "admin" ?
            <div className="flex gap-2">
              <Input className="w-full" defaultValue={values && values.length ? values[key].get : ""} onChange={(e) => handleInputChange(key, e.target.value, 'get')} />
              <Button type="primary" rounded={false} onClick={() => handleGet(item)}>Get</Button>
            </div>
            : <></>}
          </div>
        )}
      </div>
    </WidgetBase>
  );
}

export default WidgetInputs;
