import { useEffect, useState } from "react";
import debounce from "../../utils/debounce";

const BarChart = ({min, max, value, unit, color = '#F38A4F'}) => {
  const [ref, setRef] = useState();
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth, 
  });
  const itemWidth = 10;
  const itemHeight = 30;
  const gap = 5;

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        width: window.innerWidth,
      });
    }, 1000);

    window.addEventListener('resize', debouncedHandleResize);

    return _ => {
      window.removeEventListener('resize', debouncedHandleResize);
    }
  }, []);

  function fillBars() {
    let length = 10;
    if (ref) {
      length = (ref.clientWidth * ((value - min) / (max - min))) / (itemWidth + gap);
    }

    return bgBars(color, length);
  }

  function bgBars(color, size) {
    let bars = [];
    let barCount = size != null ? size : 10;

    if (ref && size == null) {
      barCount = ref.clientWidth / (itemWidth + gap);
    }

    for (let i = 0; i < barCount; i++) {
      bars.push({
        x: (i * (itemWidth + gap)) + 5,
        y: 5,
      });
    }

    return (
      <>
      {bars.map((bar) =>
      <rect x={bar.x} y={bar.y} width={10} height={itemHeight} fill={color ? color : "#818181"} />
      )}
      </>
    );
  }

  return (
    <div>
      <svg className="w-full h-[70px]" ref={newRef => setRef(newRef)}>
        <g>
          <g>
          {bgBars()}
          {fillBars()}
          </g>
          <rect className="w-full" width={ref ? ref.clientWidth : 100} height={40} strokeWidth={2} stroke={color} fill="transparent" />
          <text className="font-future" y={64} x={0} fill="#ffffff">{min}{unit}</text>
          <text className="font-future" y={64} x={ref ? (ref.clientWidth - 20) / 2 : 40} fill={color}>{value}{unit}</text>
          <text className="font-future" y={64} x={ref ? ref.clientWidth - 50 : 50} fill="#ffffff">{max}{unit}</text>
        </g>
      </svg>
    </div>
  );
}

export default BarChart;
